import React, {useEffect} from 'react';
import queryString from 'query-string';
import API from '../../services/API';

export default (props) => {

  const params = queryString.parse(props.location.search);
  const sessionId = params.session_id;

  useEffect(() => {
    if (sessionId) {
      API.getSignUp(sessionId).then(response => {
        window.location.replace('https://www.clickchauffeur.com/validation-commande');
      });
    }
  }, []);

  return (
    <>
    </>
  );
};