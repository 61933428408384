import React, {useEffect} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUnlockAlt} from '@fortawesome/free-solid-svg-icons';
import {Button, Col, Container, Form, Image, InputGroup, Row} from '@themesberg/react-bootstrap';
import Utils from '../../services/Utils';
import Logo from '../../assets/img/logo.png';
import {FormattedMessage} from 'react-intl';
import API from '../../services/API';
import {useHistory} from 'react-router-dom';
import queryString from 'query-string';
import Error from '../../services/Error';


export default (props) => {
  Utils.setBackground(true);

  const {intl} = props;
  const history = useHistory();
  const [password, setPassword] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    Utils.checkAccessTokenForLogin(history);
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const params = queryString.parse(props.location.search);
    const token = params.token;
    setLoading(true);
    API.postChangePassword(password, token).then((response => {
      setLoading(false);
      if (response && response.data.success === true) {
        Utils.notify(intl.formatMessage({id: 'PASSWORD'}), intl.formatMessage({id: 'PASSWORD_CHANGED'}));
      } else {
        if (response && response.data.data === Error.tokenExpired()) {
          Utils.notifyWarning(intl.formatMessage({id: 'PASSWORD'}), intl.formatMessage({id: 'RESET_PASSWORD_EXPIRED'}));
        } else {
          Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
        }
      }
      history.push('/');
    })).catch(error => {
      setLoading(false);
      Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
    });
  }

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-5 mb-lg-5">
        <Container>
          <a href="https://clickchauffeur.com"><Image src={Logo} alt="Logo" className="logo"/></a>
          <Row className="justify-content-center">
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3><FormattedMessage id="RESET_PASSWORD"/></h3>
                </div>
                <Form onSubmit={handleSubmit}>
                  <Form.Group id="password" className="mb-4">
                    <Form.Label><FormattedMessage id="PASSWORD"/></Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt}/>
                      </InputGroup.Text>
                      <Form.Control required type="password" placeholder={intl.formatMessage({id: 'YOUR_NEW_PASSWORD'})}
                                    minLength="8"
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password}/>
                    </InputGroup>
                  </Form.Group>
                  <Button variant="primary" type="submit" className="w-100 btn-login" disabled={loading}>
                    {loading && (
                      <i className="spinner-border spinner-border-sm text-light"/>
                    )}
                    {!loading && <FormattedMessage id="CHANGE_MY_PASSWORD"/>}
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
