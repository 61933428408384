import React, {useEffect, useReducer} from 'react';
import {Button, Card, Col, Form, InputGroup, Modal, OverlayTrigger, Row, Table, Tooltip} from '@themesberg/react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars, faCheck, faCopy, faMap, faPenToSquare, faPlus, faPowerOff, faTrashCan, faXmark} from '@fortawesome/free-solid-svg-icons';
import {FormattedMessage} from 'react-intl';
import {useHistory} from 'react-router-dom';
import Utils from '../services/Utils';
import API from '../services/API';
import MapModal from '../components/MapModal';
import Error from '../services/Error';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';


export default (props) => {

  const formReducer = (state, event) => {
    if (event.value !== undefined) {
      return {
        ...state,
        [event.name]: event.value
      }
    } else {
      return event;
    }
  }

  const {intl} = props;
  const history = useHistory();
  const focus = ['focus'];
  const hover = ['hover'];
  const [showModal, setShowModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [editModalMode, setEditModalMode] = React.useState(false);
  const [currentPackageId, setCurrentPackageId] = React.useState(false);
  const [currentPackageName, setCurrentPackageName] = React.useState('');
  const [showPickupMapModal, setShowPickupMapModal] = React.useState(false);
  const [showDepositMapModal, setShowDepositMapModal] = React.useState(false);
  const [drawMap, setDrawMap] = React.useState(true);
  const [vehicles, setVehicles] = React.useState([]);
  const [packages, setPackages] = React.useState([]);
  const [formData, setFormData] = useReducer(formReducer, {});
  const [currency, setCurrency] = React.useState({code: 'EUR', symbol: '€'});
  const [formatter, setFormatter] = React.useState(new Intl.NumberFormat(navigator.language, {style: 'currency', currency: 'EUR'}));

  const refreshPackages = () => {
    API.getPackages(history).then(response => {
      if (response && response.data.success === true) {
        const packages = response.data.data.packages.sort(function (a, b) {
          return a.position - b.position
        });
        setPackages(packages);
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  useEffect(() => {
    if (!Utils.isAccessToken(history)) {
      return;
    }
    refreshPackages();
    API.getCurrency(history).then(response => {
      if (response && response.data.success === true) {
        setFormatter(new Intl.NumberFormat(navigator.language, {
          style: 'currency',
          currency: response.data.data.code,
        }));
        setCurrency(response.data.data);
      }
    });
  }, []);

  const getVehicles = () => {
    API.getVehicles(history).then(response => {
      if (response && response.data.success === true) {
        const vehicles = response.data.data.vehicles.sort(function (a, b) {
          return a.position - b.position
        });
        setVehicles(vehicles);
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  const handleChange = event => {
    setFormData({
      name: event.target.id,
      value: event.target.value
    });
  }

  const handleAddOrEdit = (e) => {
    e.preventDefault();

    if (vehicles.length === 0) {
      getVehicles();
    }

    if (editModalMode) {

      if (!formData.vehicleId) {
        formData.vehicleId = -1; // All vehicles
      }

      API.putPackages(history, formData, currentPackageId).then(response => {
        if (response && response.data.success === true) {
          Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'SAVED_CHANGES'}));
          setShowModal(false);
          refreshPackages();
        } else {
          Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
        }
      });
    } else {

      if (!formData.vehicleId) {
        formData.vehicleId = -1; // All vehicles
      }

      if (!formData.way) {
        formData.way = 'EACH_WAY';
      }

      API.postPackages(history, formData).then(response => {
        if (response && response.data.success === true) {
          Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'PACKAGE_ADDED'}));
          setShowModal(false);
          refreshPackages();
        } else {
          Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
        }
      });
    }

  }

  const handleNewPackageButton = () => {
    if (vehicles.length === 0) {
      getVehicles();
    }

    setFormData({});
    setEditModalMode(false);
    setShowModal(true);
  }

  const handleEditPackageButton = (props) => {
    let data = Object.assign({}, props);

    if (vehicles.length === 0) {
      getVehicles();
    }

    setFormData(data);
    setCurrentPackageId(data.id);
    setEditModalMode(true);
    setShowModal(true);
  }

  const handleCopyPackageButton = (props) => {
    API.getCopyPackage(history, props.id).then(response => {
      if (response && response.data.success === true) {
        Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'PACKAGE_COPY'}));
        refreshPackages();
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  const handleEnableOrDisableButton = (props) => {
    if (props.active) {
      API.getDisablePackage(history, props.id).then(response => {
        if (response && response.data.success === true) {
          Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'PACKAGE_DISABLED'}));
          refreshPackages();
        } else {
          Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
        }
      });
    } else {
      API.getEnablePackage(history, props.id).then(response => {
        if (response && response.data.success === true) {
          Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'PACKAGE_ENABLED'}));
          refreshPackages();
        } else if (response && response.data.success === false && response.data.data === Error.packageCannotBeEnabledWithoutVehicule()) {
          Utils.notifyWarning(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'YOU_MUST_ASSIGN_VEHICLE_BEFORE'}));
        } else {
          Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
        }
      });
    }
  }

  const handleDeletePackageButton = (props) => {
    setCurrentPackageId(props.id);
    setCurrentPackageName(props.name);
    setShowDeleteModal(true);
  }

  const deletePackage = () => {
    API.deletePackages(history, currentPackageId).then(response => {
      if (response && response.data.success === true) {
        Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'PACKAGE_DELETED'}));
        setShowDeleteModal(false);
        refreshPackages();
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  const handleShowPickupMapModal = () => {
    setShowPickupMapModal(true)

    if (formData.pickupAreaCoords) {
      setDrawMap(false);
    } else {
      setDrawMap(true);
    }
  }

  const handleShowDepositMapModal = () => {
    setShowDepositMapModal(true)

    if (formData.depositAreaCoords) {
      setDrawMap(false);
    } else {
      setDrawMap(true);
    }
  }

  const setPickupAreaCoords = (coords) => {
    setFormData({
      name: 'pickupAreaCoords',
      value: coords
    });
  }

  const setDepositAreaCoords = (coords) => {
    setFormData({
      name: 'depositAreaCoords',
      value: coords
    });
  }

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    // update position variable
    let index = 0;
    for (const [key, value] of Object.entries(result)) {
      value.position = index++;
    }

    return result;
  };

  const handleDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      packages,
      result.source.index,
      result.destination.index
    );

    setPackages(items);

    // Prepare data for backend
    let data = {
      packages: []
    };

    for (const [key, value] of Object.entries(items)) {
      data.packages.push({
        id: value.id,
        position: value.position
      });
    }

    API.postReorderPackages(history, data).then(response => {
      if (response && response.data.success === true) {
        // Everything is OK
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  const TableRow = (props) => {
    const {key, provided, name, vehicleId, vehicleName, fixedPrice, increasedPrice, way, active} = props;
    let wayText = '';

    if (way === 'ONE_WAY') {
      wayText = intl.formatMessage({id: 'IN_ONE_WAY'});
    } else if (way === 'EACH_WAY') {
      wayText = intl.formatMessage({id: 'IN_EACH_WAY'});
    }

    return (
      <tr key={key} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
        <td className="td-center">
          <FontAwesomeIcon icon={faBars}/>
        </td>
        <td className="td-center">
          {name}
        </td>
        <td className="td-center">
          {vehicleId === -1 ? intl.formatMessage({id: 'ALL_VEHICLES'}) : vehicleName}
        </td>
        <td className="td-center">
          {formatter.format(fixedPrice)}
        </td>
        <td className="td-center">
          {formatter.format(increasedPrice)}
        </td>
        <td className="td-center">
          {wayText}
        </td>
        <td className="td-center">
          {active ? <FontAwesomeIcon icon={faCheck}/> : <FontAwesomeIcon icon={faXmark}/>}
        </td>
        <td className="td-center">
          <OverlayTrigger trigger={hover}
                          overlay={
                            <Tooltip><FormattedMessage id="EDIT"/></Tooltip>
                          }>
            <Button style={{marginRight: '5px'}} variant="light" onClick={() => handleEditPackageButton(props)}>
              <FontAwesomeIcon icon={faPenToSquare}/>
            </Button>
          </OverlayTrigger>
          <OverlayTrigger trigger={hover}
                          overlay={
                            <Tooltip><FormattedMessage id="COPY"/></Tooltip>
                          }>
            <Button style={{marginRight: '5px'}} variant="gray" onClick={() => handleCopyPackageButton(props)}>
              <FontAwesomeIcon icon={faCopy}/>
            </Button>
          </OverlayTrigger>
          <OverlayTrigger trigger={hover}
                          overlay={
                            <Tooltip>{active ? <FormattedMessage id="DISABLE"/> : <FormattedMessage id="ENABLE"/>}</Tooltip>
                          }>
            <Button style={{marginRight: '5px'}} variant="warning" onClick={() => handleEnableOrDisableButton(props)}>
              <FontAwesomeIcon icon={faPowerOff}/>
            </Button>
          </OverlayTrigger>
          <OverlayTrigger trigger={hover}
                          overlay={
                            <Tooltip><FormattedMessage id="DELETE"/></Tooltip>
                          }>
            <Button variant="danger" onClick={() => handleDeletePackageButton(props)}>
              <FontAwesomeIcon icon={faTrashCan}/>
            </Button>
          </OverlayTrigger>
        </td>
      </tr>
    );
  };

  return (
    <div>
      <br/>
      <Button variant="secondary" className="text-dark me-2" onClick={handleNewPackageButton}>
        <FontAwesomeIcon icon={faPlus} className="me-2"/>
        <span><FormattedMessage id="NEW_PACKAGE"/></span></Button>
      <br/><br/>
      {(packages && packages.length > 0) &&
        <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0">
            <Row>
              <Col lg={12}>
                <Table responsive className="table-centered table-nowrap rounded mb-0">
                  <thead className="thead-light">
                  <tr>
                    <th className="border-0 text-center"></th>
                    <th className="border-0 text-center"><FormattedMessage id="NAME"/></th>
                    <th className="border-0 text-center"><FormattedMessage id="VEHICLE"/></th>
                    <th className="border-0 text-center"><FormattedMessage id="FIXED_PRICE"/></th>
                    <th className="border-0 text-center"><FormattedMessage id="PRICE_WITH_SURCHARGE"/></th>
                    <th className="border-0 text-center"><FormattedMessage id="WAY"/></th>
                    <th className="border-0 text-center"><FormattedMessage id="ACTIVE_TAB"/></th>
                    <th className="border-0 text-center"><FormattedMessage id="ACTIONS"/></th>
                  </tr>
                  </thead>
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="table-body">
                      {(provided, snapshot) => (
                        <tbody ref={provided.innerRef} {...provided.droppableProps}>
                        {packages && packages.map((data, index) => {
                            return (
                              <Draggable
                                draggableId={data.id.toString()}
                                key={data.id.toString()}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <TableRow key={`package-${data.id}`} provided={provided} {...data} />
                                )}
                              </Draggable>
                            )
                          }
                        )}
                        {provided.placeholder}
                        </tbody>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Table>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      }
      <Modal as={Modal.Dialog} centered show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title className="h6">{editModalMode ? <FormattedMessage id="EDIT_PACKAGE"/> : <FormattedMessage id="NEW_PACKAGE"/>}</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={() => setShowModal(false)}/>
        </Modal.Header>
        <Modal.Body>
          <Form id="add-package-form" onSubmit={handleAddOrEdit}>
            <Row>
              <Form.Group>
                <Form.Label><FormattedMessage id="NAME"/></Form.Label>
                <Form.Control required type="text" id="name"
                              onChange={handleChange} value={formData.name}/>
              </Form.Group>
            </Row>
            <br/>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label><FormattedMessage id="FIXED_PRICE"/></Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={Utils.getCurrencyIcon(currency.code)}/>
                    </InputGroup.Text>
                    <Form.Control required type="number" id="fixedPrice" step="any"
                                  onChange={handleChange} value={formData.fixedPrice}/>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label><FormattedMessage id="PRICE_WITH_SURCHARGE"/></Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={Utils.getCurrencyIcon(currency.code)}/>
                    </InputGroup.Text>
                    <OverlayTrigger trigger={focus}
                                    overlay={
                                      <Tooltip><FormattedMessage id="TOOLTIP_PRICE_WITH_SURCHARGE"/></Tooltip>
                                    }>
                      <Form.Control required type="number" id="increasedPrice" step="any"
                                    onChange={handleChange} value={formData.increasedPrice}/>
                    </OverlayTrigger>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <br/>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group className="mb-3">
                  <Form.Label><FormattedMessage id="PICKUP_AREA"/></Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faMap}/>
                    </InputGroup.Text>
                    <Form.Control required type="text"
                                  maxLength="0"
                                  placeholder={' ' + intl.formatMessage({id: 'SELECT_ZONE'})}
                                  onClick={handleShowPickupMapModal}
                                  value={formData.pickupAreaCoords && intl.formatMessage({id: 'ZONE_SELECTED'})}/>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group className="mb-3">
                  <Form.Label><FormattedMessage id="DEPOSIT_AREA"/></Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faMap}/>
                    </InputGroup.Text>
                    <Form.Control required type="text"
                                  maxLength="0"
                                  placeholder={' ' + intl.formatMessage({id: 'SELECT_ZONE'})}
                                  onClick={handleShowDepositMapModal}
                                  value={formData.depositAreaCoords && intl.formatMessage({id: 'ZONE_SELECTED'})}/>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <br/>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group className="mb-3">
                  <Form.Label><FormattedMessage id="VEHICLE"/></Form.Label>
                  <Form.Select id="vehicleId" onChange={handleChange} value={formData.vehicleId}>
                    <option key={`vehicle-all`} value={-1}>{intl.formatMessage({id: 'ALL_VEHICLES'})}</option>
                    {vehicles.map(v => <option key={`vehicle-${v.id}`} value={v.id}>{v.name}</option>)}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group className="mb-3">
                  <Form.Label><FormattedMessage id="WAY"/></Form.Label>
                  <Form.Select id="way" onChange={handleChange} value={formData.way}>
                    <option key={`each-way`} value={'EACH_WAY'}>{intl.formatMessage({id: 'IN_EACH_WAY'})}</option>
                    <option key={`one-way`} value={'ONE_WAY'}>{intl.formatMessage({id: 'IN_ONE_WAY'})}</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" type="submit" form="add-package-form">
            {editModalMode ? <FormattedMessage id="RECORD"/> : <FormattedMessage id="ADD"/>}
          </Button>
          <Button variant="link" className="text-gray ms-auto" onClick={() => setShowModal(false)}>
            <FormattedMessage id="CLOSE"/>
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal as={Modal.Dialog} centered show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header>
          <Modal.Title className="h6"><FormattedMessage id="DELETE_PACKAGE" values={{name: currentPackageName}}/></Modal.Title>
          <Button variant="close" aria-label="Close" onClick={() => setShowDeleteModal(false)}/>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="danger" onClick={deletePackage}>
            <FormattedMessage id="DELETE"/>
          </Button>
          <Button variant="link" className="text-gray ms-auto" onClick={() => setShowDeleteModal(false)}>
            <FormattedMessage id="CLOSE"/>
          </Button>
        </Modal.Footer>
      </Modal>
      <MapModal title={intl.formatMessage({id: 'SELECT_PICKUP_AREA'})} coords={formData.pickupAreaCoords} showMapModal={showPickupMapModal} setShowMapModal={setShowPickupMapModal}
                setCoords={setPickupAreaCoords}></MapModal>
      <MapModal title={intl.formatMessage({id: 'SELECT_DEPOSIT_AREA'})} coords={formData.depositAreaCoords} showMapModal={showDepositMapModal}
                setShowMapModal={setShowDepositMapModal}
                setCoords={setDepositAreaCoords}></MapModal>
    </div>
  );
};
