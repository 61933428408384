import React, {Component, Fragment} from 'react';
import {Form} from '@themesberg/react-bootstrap';
import {FormattedMessage} from 'react-intl';

class Autocomplete extends Component {

  constructor(props) {
    super(props);

    this.state = {
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: undefined,
      // Raw suggestions
      suggestions: []
    };
  }

  componentDidUpdate(prevProps) {
    const {suggestions, value} = this.props;

    if (this.state.userInput === undefined && value) {
      this.setState({
        userInput: value
      });
    } else if (suggestions && suggestions.length > 0 && prevProps.suggestions !== suggestions) {

      // Filter our suggestions that don't contain the user's input
      const filteredSuggestions = suggestions.filter(
        suggestion =>
          suggestion.toLowerCase().indexOf(this.state.userInput.toLowerCase()) > -1
      );

      this.setState({
        suggestions,
        filteredSuggestions
      });
    }
  }

  onChange = e => {
    const {suggestions} = this.props;
    const userInput = e.currentTarget.value;

    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = suggestions.filter(
      suggestion =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value
    });
  };

  onClick = e => {
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText
    });
    const fakeEvent = {
      target: {
        id: this.props.id,
        value: e.currentTarget.innerText
      }
    }
    this.props.onChange(fakeEvent);
  };

  onKeyDown = e => {
    const {activeSuggestion, filteredSuggestions} = this.state;

    // User pressed the enter key
    if (e.keyCode === 13) {
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion]
      });
    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({activeSuggestion: activeSuggestion - 1});
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({activeSuggestion: activeSuggestion + 1});
    }
  };

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput
      }
    } = this;

    let suggestionsListComponent;

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul class="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = 'suggestion-active';
              }

              return (
                <li className={className} key={suggestion} onClick={onClick}>
                  {suggestion}
                </li>
              );
            })}
          </ul>
        );
      } else {
        suggestionsListComponent = (
          <div class="no-suggestions">
            <em><FormattedMessage id="CITY_NOT_FOUND"/></em>
          </div>
        );
      }
    }

    return (
      <Fragment>
        <Form.Control required
                      id={this.props.id}
                      type="text"
                      onChange={onChange}
                      onKeyDown={onKeyDown}
                      value={userInput}
                      disabled={this.props.disabled}
        />
        {suggestionsListComponent}
      </Fragment>
    );
  }
}

export default Autocomplete;
