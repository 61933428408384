import React, {useEffect} from 'react'
import {Button, Col, Container, Form, Image, InputGroup, Row} from '@themesberg/react-bootstrap';
import Utils from '../../services/Utils';
import Logo from '../../assets/img/logo.png';
import {FormattedMessage} from 'react-intl';
import API from '../../services/API';
import {useHistory} from 'react-router-dom';


export default (props) => {
  Utils.setBackground(true);

  const {intl} = props;
  const history = useHistory();
  const [email, setEmail] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    Utils.checkAccessTokenForLogin(history);
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    API.getResetPassword(email).then((response => {
      setLoading(false);
      if (response && response.data.success === true) {
        Utils.notify(intl.formatMessage({id: 'RESET_PASSWORD'}), intl.formatMessage({id: 'RESET_PASSWORD_EMAIL_SENT'}));
        history.push('/');
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    }));
  }

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-5 mb-lg-5">
        <Container>
          <a href="https://clickchauffeur.com"><Image src={Logo} alt="Logo" className="logo"/></a>
          <Row className="justify-content-center">
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3><FormattedMessage id="RESET_PASSWORD"/></h3>
                </div>
                <br/>
                <Form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <Form.Label htmlFor="email"><FormattedMessage id="EMAIL_ADDRESS"/></Form.Label>
                    <InputGroup id="email">
                      <Form.Control required autoFocus type="email" placeholder="example@company.com"
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}/>
                    </InputGroup>
                  </div>
                  <Button variant="primary" type="submit" className="w-100 btn-login" disabled={loading}>
                    {loading && (
                      <i className="spinner-border spinner-border-sm text-light"/>
                    )}
                    {!loading && <FormattedMessage id="RESET_MY_PASSWORD"/>}
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
