import React, {useEffect} from 'react';
import Cancel from '../../assets/img/cancel.png';
import {Button, Image} from '@themesberg/react-bootstrap';
import {FormattedMessage} from 'react-intl';
import queryString from 'query-string';
import API from '../../services/API';
import {Link, useHistory} from 'react-router-dom';
import {Routes} from '../../routes';
import Utils from '../../services/Utils';
import Error from '../../services/Error';

export default (props) => {

  const {intl} = props;
  const history = useHistory();
  const params = queryString.parse(props.location.search);
  const id = params.id;
  const token = params.token;

  useEffect(() => {
    if (id && token) {
      API.getCancelBooking(id, token).then(response => {
        if (response && response.data.success === true) {

        } else if (response && response.data.success === false && response.data.data === Error.bookingAlreadyCanceled()) {
          Utils.notifyWarning(intl.formatMessage({id: 'ALREADY_CANCELED'}), intl.formatMessage({id: 'BOOKING_ALREADY_CANCELED'}));
          history.push('/admin/dashboard');
        } else {
          Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
          history.push('/admin/dashboard');
        }
      });
    }
  }, [history, intl]);

  return (
    <div>
      {(id && token) &&
        <div className="text-center m-5">
          <Image src={Cancel} className="mb-5" style={{height: 'auto', width: '200px'}}/>
          <h1 style={{color: '#F5B759'}}><FormattedMessage id="BOOKING_CANCELED"/></h1>
          <b><FormattedMessage id="CUSTOMER_NOTIFIED_BY_EMAIL"/></b>
          <br/><br/>
          <FormattedMessage id="CLICK_TO_MANAGE_BOOKINGS"/>
          <br/><br/>
          <Button as={Link} variant="warning" className="btn-yellow" to={Routes.DashboardOverview.path}>
            <FormattedMessage id="MANAGE_BOOKINGS"/>
          </Button>
        </div>}
    </div>
  );
};