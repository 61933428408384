import React, {useEffect, useReducer} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMap, faSignOutAlt, faTrashCan} from '@fortawesome/free-solid-svg-icons';
import {Button, Card, Col, Form, InputGroup, ListGroup, Modal, OverlayTrigger, Row, Tooltip} from '@themesberg/react-bootstrap';
import {FormattedMessage} from 'react-intl';
import Countries from '../components/Countries';
import API from '../services/API';
import {useHistory} from 'react-router-dom';
import Utils from '../services/Utils';
import Autocomplete from '../components/Autocomplete';
import TimezoneSelect from 'react-timezone-select'
import MapModal from '../components/MapModal';
import {STRIPE_ACTIVE, STRIPE_CB} from '../services/constants';
import ItemStatus from '../components/ItemStatus';
import {faStripe} from '@fortawesome/free-brands-svg-icons';

export default (props) => {

  const formReducer = (state, event) => {
    if (event.name || event.value) {
      return {
        ...state,
        [event.name]: event.value
      }
    } else {
      return event;
    }
  }

  const citiesReducer = (state, data) => {
    let cities = [];
    for (const [key, value] of Object.entries(data)) {
      cities.push({
        name: value.display_name,
        lat: value.lat,
        lon: value.lon,
      })
    }
    return {
      ...state,
      cities
    }
  }

  const {intl} = props;
  const history = useHistory();
  const focus = ['focus'];
  const hover = ['hover'];
  const [showStripeDisconnectModal, setShowStripeDisconnectModal] = React.useState(false);
  const [showMapModal, setShowMapModal] = React.useState(false);
  const [drawMap, setDrawMap] = React.useState(true);
  let [searchCitiesList, setSearchCitiesCityList] = useReducer(citiesReducer, {});
  let [autoCompleteCityList, setAutoCompleteCityList] = React.useState([]);
  const [formData, setFormData] = useReducer(formReducer, {});
  const [lengthUnit, setLengthUnit] = React.useState('km');
  const [currentLengthUnit, setCurrentLengthUnit] = React.useState('km');
  const [loadingManageStripe, setLoadingManageStripe] = React.useState(false);
  const stripeActive = localStorage.getItem(STRIPE_ACTIVE);
  const stripeCB = localStorage.getItem(STRIPE_CB);
  const stripeInfos = [
    {
      'id': 1,
      'title': intl.formatMessage({id: 'STRIPE_ACCOUNT'}),
      'statusKey': stripeActive === 'true' ? 'active' : 'inactive',
    },
    {
      'id': 2,
      'title': intl.formatMessage({id: 'CARD_PAYMENTS'}),
      'statusKey': stripeCB,
    }];

  useEffect(() => {
    if (!Utils.isAccessToken(history)) {
      return;
    }

    API.getSettings(history).then(response => {
      if (response && response.data.success === true) {
        setFormData(response.data.data);
        setLengthUnit(response.data.data.lengthUnit === 'MILE' ? 'mi' : 'km');
        setCurrentLengthUnit(response.data.data.lengthUnit === 'MILE' ? 'mi' : 'km');
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });

    // autocomplete autosearch
    let typingTimer;                // timer identifier
    let doneTypingInterval = 1000;  // time in ms (5 seconds)
    let homeCityInput = document.getElementById('homeCityName');

    // on keyup, start the countdown
    homeCityInput.addEventListener('keyup', () => {
      clearTimeout(typingTimer);
      if (homeCityInput.value) {
        typingTimer = setTimeout(doneTyping, doneTypingInterval);
      }
    });

    // user is "finished typing," do something
    function doneTyping() {
      API.getNominatimSearchCity(homeCityInput.value).then(response => {
        if (response.data) {
          setSearchCitiesCityList(response.data);

          const array = [];
          for (const [key, value] of Object.entries(response.data)) {
            array.push(value.display_name);
          }
          setAutoCompleteCityList(array);
        }
      });
    }
  }, [history, intl]);

  const handleSave = (e) => {
    e.preventDefault();

    if (formData.checkboxCountry && !formData.restrictedCountry) {
      formData.restrictedCountry = 'FR';
    }

    if (formData.checkboxHomeCity && searchCitiesList.cities && searchCitiesList.cities.length > 0) {
      for (const [key, value] of Object.entries(searchCitiesList.cities)) {
        if (value.name === formData.homeCityName) {
          formData.homeCityCoords = value.lat + ',' + value.lon;
        }
      }
    }

    if (!formData.timeZone) {
      formData.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    if (formData.checkboxHomeCity && !formData.homeCityCoords) {
      Utils.notifyWarning(intl.formatMessage({id: 'HOMECITY_NOT_FOUND'}), intl.formatMessage({id: 'NOTIFY_HOMECITY_NOT_FOUND'}));
    } else if (!formData.checkboxDriverPayments && !formData.checkboxOnlinePayments) {
      Utils.notifyWarning(intl.formatMessage({id: 'PAYMENT_METHOD_NOT_SELECTED'}), intl.formatMessage({id: 'PLEASE_TICK_ONE_PAYMENT_METHOD'}));
    } else {
      if (currentLengthUnit !== lengthUnit) {
        Utils.notify(intl.formatMessage({id: 'LENGTH_UNIT_CHANGED'}), intl.formatMessage({id: 'NOTIFY_LENGTH_UNIT'}));
      }
      API.postSettings(history, formData).then(response => {
        if (response && response.data.success === true) {
          Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'SAVED_CHANGES'}));
          setCurrentLengthUnit(lengthUnit);
        } else {
          Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
        }
      });
    }
  }

  const handleChange = event => {
    const isCheckbox = event.target.type === 'checkbox';
    setFormData({
      name: event.target.id,
      value: isCheckbox ? event.target.checked : event.target.value
    })
  }

  const handleChangeTimeZone = event => {
    setFormData({
      name: 'timeZone',
      value: event.value
    })
  }

  const handleChangeImageUpload = event => {
    // 1MB max
    if (event.target.files[0].size >= 1000000) {
      Utils.notifyWarning(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'FILE_TOO_LARGE'}));
    } else {
      API.postImage(history, {
        file: event.target.files[0]
      }).then(response => {
        if (response && response.data.success === true) {
          Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'IMAGE_FORM_UPLOADED'}));
          setFormData({
            name: 'img',
            value: true
          })
        } else {
          Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
        }
      });
    }
  }

  const handleChangeLengthUnit = event => {
    setFormData({
      name: event.target.id,
      value: event.target.value
    })
    setLengthUnit(event.target.value === 'MILE' ? 'mi' : 'km');
  }

  const deleteImage = event => {
    API.deleteImage(history).then(response => {
      if (response && response.data.success === true) {
        Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'IMAGE_FORM_DELETED'}));
        setFormData({
          name: 'img',
          value: false
        })
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  const getBookingBufferTimeValue = (rawValue) => {
    return rawValue ? rawValue.substring(0, rawValue.length - 1) : '0';
  }

  const getBookingBufferTimeCharValue = (rawValue) => {
    return rawValue ? rawValue.slice(-1) : 'm';
  }

  const handleBookingBufferTimeChange = event => {
    const char = getBookingBufferTimeCharValue(formData.bookingBufferTime);
    setFormData({
      name: 'bookingBufferTime',
      value: event.target.value + char
    })
  }

  const handleBookingBufferTimeCharChange = event => {
    const timeValue = getBookingBufferTimeValue(formData.bookingBufferTime);
    setFormData({
      name: 'bookingBufferTime',
      value: timeValue + event.target.value
    })
  }

  const handleShowMapModal = () => {
    setShowMapModal(true)

    if (formData.allowedRegionCoords) {
      setDrawMap(false);
    } else {
      setDrawMap(true);
    }
  }

  const setAllowedRegionCoords = (coords) => {
    formData.allowedRegionCoords = coords;
  }

  const handleStripeManage = (e) => {
    setLoadingManageStripe(true)
    API.getStripeUrl(history).then(response => {
      if (response && response.data.success === true) {
        window.location.replace(response.data.data);
      } else {
        setLoadingManageStripe(false);
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  const handleStripeDisconnect = (e) => {
    API.getStripeDisconnect(history).then(response => {
      if (response && response.data.success === true) {
        Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'STRIPE_DISCONNECTED'}));
        localStorage.removeItem(STRIPE_ACTIVE);
        localStorage.removeItem(STRIPE_CB);
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
      setShowStripeDisconnectModal(false);
    });
  }

  return (
    <Row>
      <Col xs={12} xl={9}>
        <Card border="light" className="bg-white shadow-sm mb-4">
          <Card.Body>
            <h5 className="mb-4"><FormattedMessage id="MODULE_CUSTOMIZATION"/></h5>
            <Form id="settings-form" onSubmit={handleSave}>
              <Row>
                <Col sm={6} className="mb-3">
                  <Form.Group id="url">
                    <Form.Label><FormattedMessage id="YOUR_WEBSITE_URL"/></Form.Label>
                    <OverlayTrigger trigger={focus}
                                    overlay={
                                      <Tooltip><FormattedMessage id="TOOLTIP_YOUR_WEBSITE_URL"/></Tooltip>
                                    }>
                      <Form.Control required type="text" id="url"
                                    onChange={handleChange} value={formData.url}/>
                    </OverlayTrigger>
                  </Form.Group>
                </Col>
                <Col sm={6} className="mb-3">
                  <Form.Group id="title">
                    <Form.Label><FormattedMessage id="FORM_TITLE"/></Form.Label>
                    <OverlayTrigger trigger={focus}
                                    overlay={
                                      <Tooltip><FormattedMessage id="TOOLTIP_FORM_TITLE"/></Tooltip>
                                    }>
                      <Form.Control type="text" id="title"
                                    onChange={handleChange} value={formData.title}/>
                    </OverlayTrigger>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6} className="mb-3">
                  <Form.Group id="defaultLanguageSelect">
                    <Form.Label><FormattedMessage id="DEFAULT_LANGUAGE"/></Form.Label>
                    <Form.Select id="defaultLanguage" defaultValue="fr" onChange={handleChange} value={formData.defaultLanguage}>
                      <option value="fr">{intl.formatMessage({id: 'FRENCH'})}</option>
                      <option value="en">{intl.formatMessage({id: 'ENGLISH'})}</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3" style={{width: '49%'}}>
                  <Row>
                    <Col md={formData.img ? 11 : 12} className="mb-3" style={{paddingRight: 0}}>
                      <Form.Group id="imgUrl">
                        <Form.Label><FormattedMessage id="FORM_IMAGE"/></Form.Label>
                        <Form.Control type="file" onChange={handleChangeImageUpload} accept="image/*"/>
                      </Form.Group>
                    </Col>
                    <Col md={formData.img ? 1 : 0} className="mb-3" style={{paddingLeft: 0}}>
                      {formData.img && <div><Form.Label>&nbsp;</Form.Label>
                        <OverlayTrigger trigger={hover}
                                        overlay={
                                          <Tooltip><FormattedMessage id="DELETE_CURRENT_IMAGE"/></Tooltip>
                                        }>
                          <Button variant="danger"
                                  onClick={deleteImage}>
                            <FontAwesomeIcon icon={faTrashCan}/>
                          </Button>
                        </OverlayTrigger>
                      </div>}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col md={6} className="mb-3">
                  <Form.Label><FormattedMessage id="TIME_ZONE"/></Form.Label>
                  <TimezoneSelect
                    value={formData.timeZone ? formData.timeZone : Intl.DateTimeFormat().resolvedOptions().timeZone}
                    onChange={handleChangeTimeZone}
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group>
                    <Form.Label><FormattedMessage id="SELECT_COUNTRY_RESTRICTION_AUTOCOMPLETE"/></Form.Label>
                    <Countries id="restrictedCountry" disabled={!formData.checkboxCountry} onChange={handleChange} value={formData.restrictedCountry} intl={intl}/>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6} className="mb-3">
                  <Form.Group className="mb-3">
                    <Form.Label><FormattedMessage id="MSG_FOR_HOLIDAY_MODE"/></Form.Label>
                    <OverlayTrigger trigger={focus}
                                    overlay={
                                      <Tooltip><FormattedMessage id="TOOLTIP_MSG_FOR_HOLIDAY_MODE"/></Tooltip>
                                    }>
                      <Form.Control as="textarea" id="msgHolidayMode"
                                    onChange={handleChange} value={formData.msgHolidayMode}/>
                    </OverlayTrigger>
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group className="mb-3">
                    <Form.Label><FormattedMessage id="MSG_FOR_VEHICLE_FULL"/></Form.Label>
                    <OverlayTrigger trigger={focus}
                                    overlay={
                                      <Tooltip><FormattedMessage id="TOOLTIP_MSG_FOR_VEHICLE_FULL"/></Tooltip>
                                    }>
                      <Form.Control as="textarea" id="msgVehicleBooked"
                                    onChange={handleChange} value={formData.msgVehicleBooked}/>
                    </OverlayTrigger>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={3} className="mb-3">
                  <Form.Group>
                    <Form.Label><FormattedMessage id="CURRENCY"/></Form.Label>
                    <Form.Select id="currency" defaultValue="EUR" onChange={handleChange} value={formData.currency}>
                      {formData.availableCurrencies && formData.availableCurrencies.map(currency => (
                        <option key={currency} value={currency}>{intl.formatMessage({id: currency})}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={3} className="mb-3">
                  <Form.Group>
                    <Form.Label><FormattedMessage id="LENGTH_UNIT"/></Form.Label>
                    <Form.Select id="lengthUnit" defaultValue="KM" onChange={handleChangeLengthUnit} value={formData.lengthUnit}>
                      <option value="KM">km</option>
                      <option value="MILE">mile</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm={3} className="mb-3">
                  <Form.Group id="bookingBufferTime">
                    <Form.Label><FormattedMessage id="BOOKING_BUFFER_TIME"/></Form.Label>
                    <OverlayTrigger trigger={focus}
                                    overlay={
                                      <Tooltip><FormattedMessage id="TOOLTIP_BOOKING_BUFFER_TIME"/></Tooltip>
                                    }>
                      <Form.Control required type="number" id="bookingBufferTime"
                                    onChange={handleBookingBufferTimeChange} value={getBookingBufferTimeValue(formData.bookingBufferTime)}/>
                    </OverlayTrigger>
                  </Form.Group>
                </Col>
                <Col md={3} className="mb-3">
                  <Form.Group id="bookingBufferTimeSelect">
                    <Form.Label>&nbsp;</Form.Label>
                    <Form.Select defaultValue="m" onChange={handleBookingBufferTimeCharChange} value={getBookingBufferTimeCharValue(formData.bookingBufferTime)}>
                      <option value="m">{intl.formatMessage({id: 'MINUTES'})}</option>
                      <option value="h">{intl.formatMessage({id: 'HOURS'})}</option>
                      <option value="d">{intl.formatMessage({id: 'DAYS'})}</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>

              <h5 className="my-4"><FormattedMessage id="DISTANCE_MAX_COST_FOR_RIDE"/></h5>
              <Row>
                <Col sm={6} className="mb-3">
                  <Form.Group id="minDistance">
                    <Form.Label><FormattedMessage id="MIN_DISTANCE"/> ({lengthUnit})</Form.Label>
                    <OverlayTrigger trigger={focus}
                                    overlay={
                                      <Tooltip><FormattedMessage id="TOOLTIP_MIN_DISTANCE"/></Tooltip>
                                    }>
                      <Form.Control required type="number" id="minDistance" disabled={!formData.checkboxMinDistance}
                                    onChange={handleChange} value={formData.minDistance}/>
                    </OverlayTrigger>
                  </Form.Group>
                </Col>
                <Col sm={6} className="mb-3">
                  <Form.Group id="maxDistance">
                    <Form.Label><FormattedMessage id="MAX_DISTANCE"/> ({lengthUnit})</Form.Label>
                    <OverlayTrigger trigger={focus}
                                    overlay={
                                      <Tooltip><FormattedMessage id="TOOLTIP_MAX_DISTANCE"/></Tooltip>
                                    }>
                      <Form.Control required type="number" id="maxDistance" disabled={!formData.checkboxMaxDistance}
                                    onChange={handleChange} value={formData.maxDistance}/>
                    </OverlayTrigger>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6} className="mb-3">
                  <Form.Group className="mb-3">
                    <Form.Label><FormattedMessage id="MSG_FOR_MIN_DISTANCE"/></Form.Label>
                    <OverlayTrigger trigger={focus}
                                    overlay={
                                      <Tooltip><FormattedMessage id="TOOLTIP_MSG_FOR_MIN_DISTANCE"/></Tooltip>
                                    }>
                      <Form.Control as="textarea" id="msgErrorMinDistance" disabled={!formData.checkboxMinDistance}
                                    onChange={handleChange} value={formData.msgErrorMinDistance}/>
                    </OverlayTrigger>
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group className="mb-3">
                    <Form.Label><FormattedMessage id="MSG_FOR_MAX_DISTANCE"/></Form.Label>
                    <OverlayTrigger trigger={focus}
                                    overlay={
                                      <Tooltip><FormattedMessage id="TOOLTIP_MSG_FOR_MAX_DISTANCE"/></Tooltip>
                                    }>
                      <Form.Control as="textarea" id="msgErrorMaxDistance" disabled={!formData.checkboxMaxDistance}
                                    onChange={handleChange} value={formData.msgErrorMaxDistance}/>
                    </OverlayTrigger>
                  </Form.Group>
                </Col>
              </Row>

              <h5 className="my-4"><FormattedMessage id="ALLOWED_REGION"/></h5>
              <Row>
                <Col md={6} className="mb-3">
                  <Form.Group className="mb-3">
                    <Form.Label><FormattedMessage id="MSG_ALLOWED_REGION"/></Form.Label>
                    <OverlayTrigger trigger={focus}
                                    overlay={
                                      <Tooltip><FormattedMessage id="TOOLTIP_MSG_ALLOWED_REGION"/></Tooltip>
                                    }>
                      <Form.Control as="textarea" id="msgErrorAllowedRegion" disabled={!formData.checkboxAllowedRegion}
                                    onChange={handleChange} value={formData.msgErrorAllowedRegion}/>
                    </OverlayTrigger>
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group className="mb-3">
                    <Form.Label>&nbsp;</Form.Label>
                    <OverlayTrigger trigger={hover}
                                    overlay={
                                      <Tooltip><FormattedMessage id="TOOLTIP_SELECT_ALLOWED_REGION"/></Tooltip>
                                    }>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faMap}/>
                        </InputGroup.Text>
                        <Form.Control required type="text" disabled={!formData.checkboxAllowedRegion}
                                      maxLength="0"
                                      placeholder={' ' + intl.formatMessage({id: 'SELECT_ALLOWED_REGION'})}
                                      onClick={handleShowMapModal}
                                      value={formData.allowedRegionCoords && intl.formatMessage({id: 'REGION_SELECTED'})}/>
                      </InputGroup>
                    </OverlayTrigger>
                  </Form.Group>
                </Col>
              </Row>

              <h5 className="my-4"><FormattedMessage id="APPROACH_FEES"/></h5>
              <Row>
                <Col sm={4} className="mb-3">
                  <Form.Group id="groupHomeCity">
                    <Form.Label><FormattedMessage id="HOME_CITY"/></Form.Label>
                    <OverlayTrigger trigger={focus}
                                    overlay={
                                      <Tooltip><FormattedMessage id="TOOLTIP_HOME_CITY"/></Tooltip>
                                    }>
                      <Autocomplete
                        id="homeCityName"
                        onChange={handleChange}
                        value={formData.homeCityName}
                        suggestions={autoCompleteCityList} disabled={!formData.checkboxHomeCity}/>
                    </OverlayTrigger>
                  </Form.Group>
                </Col>
                <Col sm={4} className="mb-3">
                  <Form.Group id="groupHomeCityPrice">
                    <Form.Label>{lengthUnit === 'mi' && <FormattedMessage id="PRICE_PER_MILE"/>}{lengthUnit === 'km' && <FormattedMessage id="PRICE_PER_KM"/>}</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={Utils.getCurrencyIcon(formData.currency)}/>
                      </InputGroup.Text>
                      <Form.Control required type="number" id="homeCityPrice" step="any" disabled={!formData.checkboxHomeCity}
                                    onChange={handleChange} value={formData.homeCityPrice}/>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col sm={4} className="mb-3">
                  <Form.Group id="groupHomeCityDistance">
                    <Form.Label><FormattedMessage id="MIN_DISTANCE_BEFORE_PRICING"/> ({lengthUnit})</Form.Label>
                    <OverlayTrigger trigger={focus}
                                    overlay={
                                      <Tooltip><FormattedMessage id="TOOLTIP_MIN_DISTANCE_BEFORE_PRICING"/></Tooltip>
                                    }>
                      <Form.Control required type="number" id="homeCityDistance" disabled={!formData.checkboxHomeCity}
                                    onChange={handleChange} value={formData.homeCityDistance}/>
                    </OverlayTrigger>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Col>
      <Col xs={12} xl={3}>
        <Row>
          <Col xs={12}>
            <Card border="light" className="bg-white shadow-sm mb-4">
              <Card.Body>
                <h5 className="mb-4"><FormattedMessage id="PAYMENTS"/></h5>
                <Form>
                  <OverlayTrigger trigger={hover}
                                  overlay={
                                    <Tooltip><FormattedMessage id="TOOLTIP_DRIVER_PAYMENTS"/></Tooltip>
                                  }>
                    <Form.Check label={intl.formatMessage({id: 'DRIVER_PAYMENTS'})} id="checkboxDriverPayments"
                                onChange={handleChange}
                                checked={formData.checkboxDriverPayments || false}/>
                  </OverlayTrigger>
                  <OverlayTrigger trigger={hover}
                                  overlay={
                                    <Tooltip><FormattedMessage id="TOOLTIP_ONLINE_PAYMENTS"/></Tooltip>
                                  }>
                    <Form.Check label={intl.formatMessage({id: 'ONLINE_PAYMENTS'})} id="checkboxOnlinePayments"
                                onChange={handleChange}
                                checked={formData.checkboxOnlinePayments || false}/>
                  </OverlayTrigger>
                </Form>
                {formData.checkboxOnlinePayments === true &&
                  <div className="d-none d-sm-block">
                    {stripeActive !== null && <ListGroup className="list-group-flush list my--3">
                      {stripeInfos.map(info => <ItemStatus intl={intl} key={`stripe-infos-${info.id}`} {...info} />)}
                    </ListGroup>}
                    {stripeActive === null && <small><FormattedMessage id="BANK_DETAILS_INFO"/><br/><br/></small>}
                    <div>
                      {stripeActive !== null &&
                        <Button variant="primary" size="sm" className="me-2 mt-1" onClick={handleStripeManage} disabled={loadingManageStripe}>
                          {loadingManageStripe &&
                            <i className="spinner-border spinner-border-sm text-light"/>
                          }
                          {!loadingManageStripe &&
                            <span>
                            <FontAwesomeIcon icon={faStripe} className="me-1"/>
                              &nbsp;
                              <FormattedMessage id="MANAGE_BANK_ACCOUNT"/>
                            </span>
                          }
                        </Button>
                      }
                      {stripeActive === null &&
                        <Button variant="primary" size="sm" className="me-2 mt-1" onClick={handleStripeManage}>
                          {loadingManageStripe &&
                            <i className="spinner-border spinner-border-sm text-light"/>
                          }
                          {!loadingManageStripe &&
                            <span>
                            <FontAwesomeIcon icon={faStripe} className="me-1"/>
                              &nbsp;
                              <FormattedMessage id="CONNECT_MY_ACCOUNT"/>
                            </span>
                          }
                        </Button>
                      }
                      <span>
                         {(!loadingManageStripe && stripeActive !== null) &&
                           <OverlayTrigger trigger={hover}
                                           overlay={
                                             <Tooltip><FormattedMessage id="DISCONNECT_MY_STRIPE"/></Tooltip>
                                           }>
                             <Button variant="danger" size="sm" className="me-2 mt-1" onClick={() => setShowStripeDisconnectModal(true)}>
                               <FontAwesomeIcon icon={faSignOutAlt} className="me-1"/>
                             </Button>
                           </OverlayTrigger>}
                      </span>
                    </div>
                  </div>}
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12}>
            <Card border="light" className="bg-white shadow-sm mb-4">
              <Card.Body>
                <h5 className="mb-4"><FormattedMessage id="OPTIONS"/></h5>
                <Form>
                  <OverlayTrigger trigger={hover}
                                  overlay={
                                    <Tooltip><FormattedMessage id="TOOLTIP_COUNTRY_RESTRICTION_AUTOCOMPLETE"/></Tooltip>
                                  }>
                    <Form.Check label={intl.formatMessage({id: 'COUNTRY_RESTRICTION_AUTOCOMPLETE'})} id="checkboxCountry"
                                onChange={handleChange}
                                checked={formData.checkboxCountry || false}/>
                  </OverlayTrigger>
                  <OverlayTrigger trigger={hover}
                                  overlay={
                                    <Tooltip><FormattedMessage id="TOOLTIP_MIN_DISTANCE"/></Tooltip>
                                  }>
                    <Form.Check label={intl.formatMessage({id: 'MIN_DISTANCE'})} id="checkboxMinDistance"
                                onChange={handleChange}
                                checked={formData.checkboxMinDistance || false}/>
                  </OverlayTrigger>
                  <OverlayTrigger trigger={hover}
                                  overlay={
                                    <Tooltip><FormattedMessage id="TOOLTIP_MAX_DISTANCE"/></Tooltip>
                                  }>
                    <Form.Check label={intl.formatMessage({id: 'MAX_DISTANCE'})} id="checkboxMaxDistance"
                                onChange={handleChange}
                                checked={formData.checkboxMaxDistance || false}/>
                  </OverlayTrigger>
                  <OverlayTrigger trigger={hover}
                                  overlay={
                                    <Tooltip><FormattedMessage id="TOOLTIP_ALLOWED_REGION"/></Tooltip>
                                  }>
                    <Form.Check label={intl.formatMessage({id: 'ALLOWED_REGION'})} id="checkboxAllowedRegion"
                                onChange={handleChange}
                                checked={formData.checkboxAllowedRegion || false}/>
                  </OverlayTrigger>
                  <OverlayTrigger trigger={hover}
                                  overlay={
                                    <Tooltip><FormattedMessage id="TOOLTIP_HOME_CITY"/></Tooltip>
                                  }>
                    <Form.Check label={intl.formatMessage({id: 'APPROACH_FEES'})} id="checkboxHomeCity"
                                onChange={handleChange}
                                checked={formData.checkboxHomeCity || false}/>
                  </OverlayTrigger>
                  <OverlayTrigger trigger={hover}
                                  overlay={
                                    <Tooltip><FormattedMessage id="TOOLTIP_ROUND_PRICE"/></Tooltip>
                                  }>
                    <Form.Check label={intl.formatMessage({id: 'ROUND_PRICE'})} id="checkboxRoundPrice"
                                onChange={handleChange}
                                checked={formData.checkboxRoundPrice || false}/>
                  </OverlayTrigger>
                  <OverlayTrigger trigger={hover}
                                  overlay={
                                    <Tooltip><FormattedMessage id="TOOLTIP_HOLIDAY_MODE"/></Tooltip>
                                  }>
                    <Form.Check label={intl.formatMessage({id: 'HOLIDAY_MODE'})} id="checkboxHolidayMode"
                                onChange={handleChange}
                                checked={formData.checkboxHolidayMode || false}/>
                  </OverlayTrigger>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <div className="mt-3 text-center">
          <Button variant="primary" type="submit" form="settings-form"><FormattedMessage id="SAVE"/></Button>
        </div>
      </Col>
      <MapModal title={intl.formatMessage({id: 'SELECT_ALLOWED_REGION'})} coords={formData.allowedRegionCoords} showMapModal={showMapModal} setShowMapModal={setShowMapModal}
                setCoords={setAllowedRegionCoords}></MapModal>
      <Modal as={Modal.Dialog} centered show={showStripeDisconnectModal} onHide={() => setShowStripeDisconnectModal(false)}>
        <Modal.Header>
          <Modal.Title className="h6"><FormattedMessage id="DISCONNECT_MY_STRIPE"/></Modal.Title>
          <Button variant="close" aria-label="Close" onClick={() => setShowStripeDisconnectModal(false)}/>
        </Modal.Header>
        <Modal.Body>
          <FormattedMessage id="DISCONNECT_MY_STRIPE_INFOS"/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleStripeDisconnect}>
            <FormattedMessage id="DISCONNECT"/>
          </Button>
          <Button variant="link" className="text-gray ms-auto" onClick={() => setShowStripeDisconnectModal(false)}>
            <FormattedMessage id="CLOSE"/>
          </Button>
        </Modal.Footer>
      </Modal>
    </Row>
  );
};
