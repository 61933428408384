import React, {useEffect} from 'react';
import queryString from 'query-string';

export default (props) => {

  const params = queryString.parse(props.location.search);
  const sessionId = params.session_id;

  useEffect(() => {
    if (sessionId) {
      window.location.replace('https://www.clickchauffeur.com/validation-commande');
    }
  }, []);

  return (
    <>
    </>
  );
};