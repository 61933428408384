import React, {useEffect, useReducer} from 'react';
import {Button, ButtonGroup, Card, Col, Form, InputGroup, Modal, OverlayTrigger, Row, Table, Tooltip} from '@themesberg/react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faPenToSquare, faPlus, faPowerOff, faTrashCan, faXmark} from '@fortawesome/free-solid-svg-icons';
import {FormattedMessage} from 'react-intl';
import Utils from '../services/Utils';
import {useHistory} from 'react-router-dom';
import API from '../services/API';
import {DAYS_BORDER_COLOR} from '../services/constants';
import Error from '../services/Error';


export default (props) => {

  const formReducer = (state, event) => {
    if (event.value !== undefined) {
      return {
        ...state,
        [event.name]: event.value
      }
    } else {
      return event;
    }
  }

  const {intl} = props;
  const history = useHistory();
  const focus = ['focus'];
  const hover = ['hover'];
  const [showModal, setShowModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [editModalMode, setEditModalMode] = React.useState(false);
  const [currentSurchargeId, setCurrentSurchargeId] = React.useState(false);
  const [currentSurchargeName, setCurrentSurchargeName] = React.useState('');
  const [vehicles, setVehicles] = React.useState([]);
  const [surcharges, setSurcharges] = React.useState([]);
  const [formData, setFormData] = useReducer(formReducer, {});
  const [currency, setCurrency] = React.useState({code: 'EUR', symbol: '€'});
  const [formatter, setFormatter] = React.useState(new Intl.NumberFormat(navigator.language, {style: 'currency', currency: 'EUR'}));

  const refreshSurcharges = () => {
    API.getSurcharges(history).then(response => {
      if (response && response.data.success === true) {
        setSurcharges(response.data.data.surcharges);
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  useEffect(() => {
    if (!Utils.isAccessToken(history)) {
      return;
    }
    refreshSurcharges();
    API.getCurrency(history).then(response => {
      if (response && response.data.success === true) {
        setFormatter(new Intl.NumberFormat(navigator.language, {
          style: 'currency',
          currency: response.data.data.code,
        }));
        setCurrency(response.data.data);
      }
    });
  }, []);

  const getVehicles = () => {
    API.getVehicles(history).then(response => {
      if (response && response.data.success === true) {
        const vehicles = response.data.data.vehicles.sort(function (a, b) {
          return a.position - b.position
        });
        setVehicles(vehicles);
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  const handleChange = event => {
    setFormData({
      name: event.target.id,
      value: event.target.value
    });
  }

  const handleAddOrEdit = (e) => {
    e.preventDefault();

    if (vehicles.length === 0) {
      getVehicles();
    }

    if (editModalMode) {

      if (!formData.vehicleId) {
        formData.vehicleId = -1; // All vehicles
      }

      API.putSurcharges(history, formData, currentSurchargeId).then(response => {
        if (response && response.data.success === true) {
          Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'SAVED_CHANGES'}));
          setShowModal(false);
          refreshSurcharges();
        } else {
          Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
        }
      });
    } else {

      if (!formData.monday) {
        formData.monday = false;
      }
      if (!formData.tuesday) {
        formData.tuesday = false;
      }
      if (!formData.wednesday) {
        formData.wednesday = false;
      }
      if (!formData.thursday) {
        formData.thursday = false;
      }
      if (!formData.friday) {
        formData.friday = false;
      }
      if (!formData.saturday) {
        formData.saturday = false;
      }
      if (!formData.sunday) {
        formData.sunday = false;
      }

      if (!formData.vehicleId) {
        formData.vehicleId = -1; // All vehicles
      }

      API.postSurcharges(history, formData).then(response => {
        if (response && response.data.success === true) {
          Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'SURCHARGE_ADDED'}));
          setShowModal(false);
          refreshSurcharges();
        } else {
          Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
        }
      });
    }
  }

  const handleNewSurchargeButton = () => {
    if (vehicles.length === 0) {
      getVehicles();
    }

    setFormData({});
    setFormData({
      name: 'multiplier',
      value: 1
    });
    setEditModalMode(false);
    setShowModal(true);
  }

  const handleEditSurchargeButton = (props) => {
    let data = Object.assign({}, props);

    if (vehicles.length === 0) {
      getVehicles();
    }

    setFormData(data);
    setCurrentSurchargeId(data.id);
    setEditModalMode(true);
    setShowModal(true);
  }

  const handleEnableOrDisableButton = (props) => {
    if (props.active) {
      API.getDisableSurcharge(history, props.id).then(response => {
        if (response && response.data.success === true) {
          Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'SURCHARGE_DISABLED'}));
          refreshSurcharges();
        } else {
          Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
        }
      });
    } else {
      API.getEnableSurcharge(history, props.id).then(response => {
        if (response && response.data.success === true) {
          Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'SURCHARGE_ENABLED'}));
          refreshSurcharges();
        } else if (response && response.data.success === false && response.data.data === Error.surchargeCannotBeEnabledWithoutVehicule()) {
          Utils.notifyWarning(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'YOU_MUST_ASSIGN_VEHICLE_BEFORE'}));
        } else {
          Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
        }
      });
    }
  }

  const handleDeleteSurchargeButton = (props) => {
    setCurrentSurchargeId(props.id);
    setCurrentSurchargeName(props.name);
    setShowDeleteModal(true);
  }

  const deleteSurcharge = () => {
    API.deleteSurcharges(history, currentSurchargeId).then(response => {
      if (response && response.data.success === true) {
        Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'SURCHARGE_DELETED'}));
        setShowDeleteModal(false);
        refreshSurcharges()
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  const handleClickDays = (e) => {
    switch (e.target.id) {
      case 'monday':
        setFormData({
          name: 'monday',
          value: formData.monday !== true
        });
        break;
      case 'tuesday':
        setFormData({
          name: 'tuesday',
          value: formData.tuesday !== true
        });
        break;
      case 'wednesday':
        setFormData({
          name: 'wednesday',
          value: formData.wednesday !== true
        });
        break;
      case 'thursday':
        setFormData({
          name: 'thursday',
          value: formData.thursday !== true
        });
        break;
      case 'friday':
        setFormData({
          name: 'friday',
          value: formData.friday !== true
        });
        break;
      case 'saturday':
        setFormData({
          name: 'saturday',
          value: formData.saturday !== true
        });
        break;
      case 'sunday':
        setFormData({
          name: 'sunday',
          value: formData.sunday !== true
        });
        break;
    }
  }

  const TableRow = (props) => {
    const {name, startTime, endTime, vehicleId, vehicleName, increaseAmount, multiplier, monday, tuesday, wednesday, thursday, friday, saturday, sunday, active} = props;
    let days = '';

    if (monday) {
      days += intl.formatMessage({id: 'MONDAY'}).substring(0, 3) + '/';
    }

    if (tuesday) {
      days += intl.formatMessage({id: 'TUESDAY'}).substring(0, 3) + '/';
    }

    if (wednesday) {
      days += intl.formatMessage({id: 'WEDNESDAY'}).substring(0, 3) + '/';
    }

    if (thursday) {
      days += intl.formatMessage({id: 'THURSDAY'}).substring(0, 3) + '/';
    }

    if (friday) {
      days += intl.formatMessage({id: 'FRIDAY'}).substring(0, 3) + '/';
    }

    if (saturday) {
      days += intl.formatMessage({id: 'SATURDAY'}).substring(0, 3) + '/';
    }

    if (sunday) {
      days += intl.formatMessage({id: 'SUNDAY'}).substring(0, 3) + '/';
    }

    if (days.length > 0) {
      days = days.substring(0, days.length - 1);
    }

    return (
      <tr>
        <td className="td-center">
          {name}
        </td>
        <td className="td-center">
          {startTime}
        </td>
        <td className="td-center">
          {endTime}
        </td>
        <td className="td-center">
          {days}
        </td>
        <td className="td-center">
          {vehicleId === -1 ? intl.formatMessage({id: 'ALL_VEHICLES'}) : vehicleName}
        </td>
        <td className="td-center">
          {formatter.format(increaseAmount)}
        </td>
        <td className="td-center">
          {multiplier}
        </td>
        <td className="td-center">
          {active ? <FontAwesomeIcon icon={faCheck}/> : <FontAwesomeIcon icon={faXmark}/>}
        </td>
        <td className="td-center">
          <OverlayTrigger trigger={hover}
                          overlay={
                            <Tooltip><FormattedMessage id="EDIT"/></Tooltip>
                          }>
            <Button style={{marginRight: '5px'}} variant="light" onClick={() => handleEditSurchargeButton(props)}>
              <FontAwesomeIcon icon={faPenToSquare}/>
            </Button>
          </OverlayTrigger>
          <OverlayTrigger trigger={hover}
                          overlay={
                            <Tooltip>{active ? <FormattedMessage id="DISABLE"/> : <FormattedMessage id="ENABLE"/>}</Tooltip>
                          }>
            <Button style={{marginRight: '5px'}} variant="warning" onClick={() => handleEnableOrDisableButton(props)}>
              <FontAwesomeIcon icon={faPowerOff}/>
            </Button>
          </OverlayTrigger>
          <OverlayTrigger trigger={hover}
                          overlay={
                            <Tooltip><FormattedMessage id="DELETE"/></Tooltip>
                          }>
            <Button variant="danger" onClick={() => handleDeleteSurchargeButton(props)}>
              <FontAwesomeIcon icon={faTrashCan}/>
            </Button>
          </OverlayTrigger>
        </td>
      </tr>
    );
  };

  return (
    <div>
      <br/>
      <Button variant="secondary" className="text-dark me-2" onClick={handleNewSurchargeButton}>
        <FontAwesomeIcon icon={faPlus} className="me-2"/>
        <span><FormattedMessage id="NEW_SURCHARGE"/></span></Button>
      <br/><br/>
      {(surcharges && surcharges.length > 0) &&
        <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0">
            <Row>
              <Col lg={12}>
                <Table responsive className="table-centered table-nowrap rounded mb-0">
                  <thead className="thead-light">
                  <tr>
                    <th className="border-0 text-center"><FormattedMessage id="NAME"/></th>
                    <th className="border-0 text-center"><FormattedMessage id="START_TIME" values={{newLine: <br/>}}/></th>
                    <th className="border-0 text-center"><FormattedMessage id="END_TIME" values={{newLine: <br/>}}/></th>
                    <th className="border-0 text-center"><FormattedMessage id="WEEKDAYS"/></th>
                    <th className="border-0 text-center"><FormattedMessage id="VEHICLE"/></th>
                    <th className="border-0 text-center"><FormattedMessage id="INCREASE"/></th>
                    <th className="border-0 text-center"><FormattedMessage id="MULTIPLIER"/></th>
                    <th className="border-0 text-center"><FormattedMessage id="ACTIVE_TAB"/></th>
                    <th className="border-0 text-center"><FormattedMessage id="ACTIONS"/></th>
                  </tr>
                  </thead>
                  <tbody>
                  {surcharges && surcharges.map((data, index) => <TableRow key={`surcharge-${data.id}`} {...data} />)}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      }
      <Modal as={Modal.Dialog} centered show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title className="h6">{editModalMode ? <FormattedMessage id="EDIT_SURCHARGE"/> : <FormattedMessage id="NEW_SURCHARGE"/>}</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={() => setShowModal(false)}/>
        </Modal.Header>
        <Modal.Body>
          <Form id="add-surcharge-form" onSubmit={handleAddOrEdit}>
            <Row>
              <Form.Group>
                <Form.Label><FormattedMessage id="NAME"/></Form.Label>
                <Form.Control required type="text" id="name"
                              onChange={handleChange} value={formData.name}/>
              </Form.Group>
            </Row>
            <br/>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label><FormattedMessage id="START_TIME" values={{newLine: ' '}}/></Form.Label>
                  <Form.Control required type="time" id="startTime"
                                onChange={handleChange} value={formData.startTime}/>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label><FormattedMessage id="END_TIME" values={{newLine: ' '}}/></Form.Label>
                  <Form.Control required type="time" id="endTime"
                                onChange={handleChange} value={formData.endTime}/>
                </Form.Group>
              </Col>
            </Row>
            <br/>
            <Row>
              <Form.Label><FormattedMessage id="APPLICABLE_DAYS"/></Form.Label>
              <ButtonGroup>
                <Button id="monday" onClick={handleClickDays} variant={formData.monday ? 'secondary' : 'outline-primary'} style={{borderColor: DAYS_BORDER_COLOR}}
                        size="sm"><FormattedMessage id="MONDAY"/></Button>
                <Button id="tuesday" onClick={handleClickDays} variant={formData.tuesday ? 'secondary' : 'outline-primary'} style={{borderColor: DAYS_BORDER_COLOR}}
                        size="sm"><FormattedMessage id="TUESDAY"/></Button>
                <Button id="wednesday" onClick={handleClickDays} variant={formData.wednesday ? 'secondary' : 'outline-primary'} style={{borderColor: DAYS_BORDER_COLOR}}
                        size="sm"><FormattedMessage id="WEDNESDAY"/></Button>
                <Button id="thursday" onClick={handleClickDays} variant={formData.thursday ? 'secondary' : 'outline-primary'} style={{borderColor: DAYS_BORDER_COLOR}}
                        size="sm"><FormattedMessage id="THURSDAY"/></Button>
                <Button id="friday" onClick={handleClickDays} variant={formData.friday ? 'secondary' : 'outline-primary'} style={{borderColor: DAYS_BORDER_COLOR}}
                        size="sm"><FormattedMessage id="FRIDAY"/></Button>
                <Button id="saturday" onClick={handleClickDays} variant={formData.saturday ? 'secondary' : 'outline-primary'} style={{borderColor: DAYS_BORDER_COLOR}}
                        size="sm"><FormattedMessage id="SATURDAY"/></Button>
                <Button id="sunday" onClick={handleClickDays} variant={formData.sunday ? 'secondary' : 'outline-primary'} style={{borderColor: DAYS_BORDER_COLOR}}
                        size="sm"><FormattedMessage id="SUNDAY"/></Button>
              </ButtonGroup>
            </Row>
            <br/>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label><FormattedMessage id="INCREASE"/></Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={Utils.getCurrencyIcon(currency.code)}/>
                    </InputGroup.Text>
                    <Form.Control required type="number" id="increaseAmount" step="any"
                                  onChange={handleChange} value={formData.increaseAmount}/>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label><FormattedMessage id="MULTIPLIER"/></Form.Label>
                  <OverlayTrigger trigger={focus}
                                  overlay={
                                    <Tooltip><FormattedMessage id="TOOLTIP_SURCHARGE_MULTIPLIER"/></Tooltip>
                                  }>
                    <Form.Control required type="number" id="multiplier" step="any"
                                  onChange={handleChange} value={formData.multiplier}/>
                  </OverlayTrigger>
                </Form.Group>
              </Col>
            </Row>
            <br/>
            <Row>
              <Form.Group>
                <Form.Label><FormattedMessage id="VEHICLE"/></Form.Label>
                <Form.Select id="vehicleId" onChange={handleChange} value={formData.vehicleId}>
                  <option key={`vehicle-all`} value={-1}>{intl.formatMessage({id: 'ALL_VEHICLES'})}</option>
                  {vehicles.map(v => <option key={`vehicle-${v.id}`} value={v.id}>{v.name}</option>)}
                </Form.Select>
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" type="submit" form="add-surcharge-form">
            {editModalMode ? <FormattedMessage id="RECORD"/> : <FormattedMessage id="ADD"/>}
          </Button>
          <Button variant="link" className="text-gray ms-auto" onClick={() => setShowModal(false)}>
            <FormattedMessage id="CLOSE"/>
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal as={Modal.Dialog} centered show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header>
          <Modal.Title className="h6"><FormattedMessage id="DELETE_SURCHARGE" values={{name: currentSurchargeName}}/></Modal.Title>
          <Button variant="close" aria-label="Close" onClick={() => setShowDeleteModal(false)}/>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="danger" onClick={deleteSurcharge}>
            <FormattedMessage id="DELETE"/>
          </Button>
          <Button variant="link" className="text-gray ms-auto" onClick={() => setShowDeleteModal(false)}>
            <FormattedMessage id="CLOSE"/>
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
    ;
};
