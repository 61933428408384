import React from 'react';
import {Button, Modal} from '@themesberg/react-bootstrap';
import {FormattedMessage} from 'react-intl';

export default (props) => {
  const {icons, currentIcon, onChange} = props;
  const [showModal, setShowModal] = React.useState(false);
  const [icon, setIcon] = React.useState();

  const handleClick = (iconId) => {
    setIcon(icons[iconId]);
    onChange(iconId);
    setShowModal(false);
  }

  const renderIconButtons = () => {
    const rows = [];
    for (const [key, value] of Object.entries(icons)) {
      rows.push(<Button style={{margin: '5px'}} key={key} variant="light" onClick={() => handleClick(key)}>
        <div dangerouslySetInnerHTML={{__html: value}}/>
      </Button>);
    }
    return rows;
  }

  return (
    <div>
      <Button variant="light" onClick={() => setShowModal(true)}>
        {!icon && !currentIcon && <FormattedMessage id="CHOOSE_ICON"/>}
        {icon && <div dangerouslySetInnerHTML={{__html: icon}}/>}
        {!icon && currentIcon && <div dangerouslySetInnerHTML={{__html: icons[currentIcon]}}/>}
      </Button>
      <Modal as={Modal.Dialog} centered show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title className="h6"><FormattedMessage id="CHOOSE_ICON"/></Modal.Title>
          <Button variant="close" aria-label="Close" onClick={() => setShowModal(false)}/>
        </Modal.Header>
        <Modal.Body>
          {renderIconButtons()}
        </Modal.Body>
      </Modal>
    </div>
  );
}