import React from 'react';
import {Button, Modal} from '@themesberg/react-bootstrap';
import {FormattedMessage} from 'react-intl';
import {Circle, FeatureGroup, Map, Polygon, TileLayer, useLeaflet} from 'react-leaflet';
import {EditControl} from 'react-leaflet-draw';

export default (props) => {
  const {title, coords, showMapModal, setShowMapModal, setCoords} = props;
  const [drawMap, setDrawMap] = React.useState(true);
  let [polygonMapCoords, setPolygonMapCoords] = React.useState('');

  const ResizeMap = () => {
    const leaflet = useLeaflet();
    leaflet.map.invalidateSize();
    return null;
  };

  const onMapDrawCreated = (e) => {
    // polygon
    if (e.layer._latlngs) {
      let polygonCoords = '';
      for (const [key, value] of Object.entries(e.layer._latlngs[0])) {
        polygonCoords += value.lat + ',' + value.lng + '|';
      }
      polygonCoords = polygonCoords.slice(0, -1); // Remove last '|'
      setPolygonMapCoords(polygonCoords);
    }
    // circle
    else if (e.layer._latlng) {
      setPolygonMapCoords(e.layer._latlng.lat + ',' + e.layer._latlng.lng + '/' + e.layer._mRadius);
    }
    setDrawMap(false);
  }

  const onMapDrawEdited = (e) => {
    for (const [key, value] of Object.entries(e.layers._layers)) {
      // polygon
      if (value._latlngs) {
        let polygonCoords = '';
        for (const [key, value2] of Object.entries(value._latlngs[0])) {
          polygonCoords += value2.lat + ',' + value2.lng + '|';
        }
        polygonCoords = polygonCoords.slice(0, -1); // Remove last '|'
        polygonMapCoords = polygonCoords;
      }
      // circle
      else if (value._latlng) {
        polygonMapCoords = value._latlng.lat + ',' + value._latlng.lng + '/' + value._mRadius;
      }
    }
  }

  const onMapDrawDeleted = (e) => {
    let isDrawExist;
    for (const [key, value] of Object.entries(e.target._layers)) {
      if (value._latlngs || value._latlng) {
        isDrawExist = true;
      }
    }
    if (!isDrawExist) {
      setDrawMap(true);
      setPolygonMapCoords('');
    }
  }

  const onMapMounted = () => {
    if(!coords) {
      setDrawMap(true);
      setPolygonMapCoords('');
    }
    else {
      setDrawMap(false);
    }
  }

  const handleValidMapDraw = () => {
    setCoords(polygonMapCoords);
    setShowMapModal(false);
  }

  const convertCoords = (strCoords) => {
    let arrayCoords = []
    const splitCoords = strCoords.split('|');

    if (splitCoords.length === 1) {
      arrayCoords = JSON.parse('[' + splitCoords[0].split('/')[0] + ']');
      return JSON.parse(JSON.stringify(arrayCoords));
    } else {
      splitCoords.forEach(coord => arrayCoords.push(JSON.parse('[' + coord + ']')));
      return JSON.parse(JSON.stringify(arrayCoords));
    }
  }

  return (
    <Modal as={Modal.Dialog} centered show={showMapModal} onHide={() => setShowMapModal(false)}>
      <Modal.Header>
        <Modal.Title className="h6">{title}</Modal.Title>
        <Button variant="close" aria-label="Close" onClick={() => setShowMapModal(false)}/>
      </Modal.Header>
      <Modal.Body>
        <Map center={[46.41, 2.05]} zoom={6} scrollWheelZoom={true} style={{width: '100%', height: '500px'}}>
          <ResizeMap/>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <FeatureGroup>
            <EditControl
              position="topright"
              draw={{
                polygon: drawMap,
                circle: drawMap,
                rectangle: false,
                circlemarker: false,
                marker: false,
                polyline: false,
              }}
              onCreated={onMapDrawCreated}
              onEdited={onMapDrawEdited}
              onDeleted={onMapDrawDeleted}
              onMounted={onMapMounted}
            />
            {coords && coords.split('|').length > 1 && <Polygon positions={convertCoords(coords)}/>}
            {coords && coords.split('|').length === 1 &&
              <Circle center={convertCoords(coords)} radius={parseFloat(coords.split('/')[1])}/>}
          </FeatureGroup>
        </Map>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleValidMapDraw}>
          <FormattedMessage id="VALID"/>
        </Button>
        <Button variant="link" className="text-gray ms-auto" onClick={() => setShowMapModal(false)}>
          <FormattedMessage id="CLOSE"/>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}