import React, {useState} from 'react';
import SimpleBar from 'simplebar-react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {CSSTransition} from 'react-transition-group';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBagShopping, faEye, faMessage, faSignOutAlt, faTable, faTimes} from '@fortawesome/free-solid-svg-icons';
import {Accordion, Badge, Button, Dropdown, Form, Image, Modal, Nav, Navbar} from '@themesberg/react-bootstrap';

import {Routes} from '../routes';
import Logo from '../assets/img/loading.png';
import Profile from '../assets/img/profile.png';
import {API_URL, NAME, STRIPE_ACTIVE, STRIPE_CB, WEBSITE} from '../services/constants';
import {FormattedMessage} from 'react-intl';
import Utils from '../services/Utils';
import API from '../services/API';

export default (props = {}) => {
  const {intl} = props;
  const history = useHistory();
  const location = useLocation();
  const {pathname} = location;
  const [show, setShow] = useState(true);
  const [showModuleModal, setShowModuleModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [contactMessage, setContactMessage] = useState('');
  const [moduleIdentifier, setModuleIdentifier] = useState('');
  const [stripeInfosLoaded, setStripeInfosLoaded] = useState(false);
  const showClass = show ? 'show' : '';
  const name = localStorage.getItem(NAME);

  const handleLogout = (e) => {
    Utils.logout(history);
  }

  const handlePreviewModule = () => {
    if (!moduleIdentifier) {
      API.getModule(history).then(response => {
        if (response && response.data.success === true) {
          setModuleIdentifier(response.data.data.identifier)
        }
        setShowModuleModal(true);
      });
    } else {
      setShowModuleModal(true);
    }
  }

  const handleContactUs = (e) => {
    e.preventDefault();
    API.postContactUs(history, contactMessage).then(response => {
      if (response && response.data.success === true) {
        Utils.notifySuccess(intl.formatMessage({id: 'MESSAGE_SENT'}), intl.formatMessage({id: 'WE_WILL_PROCESS_REQUEST'}));
        setShowContactModal(false);
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  const onCollapse = () => setShow(!show);

  const CollapsableNavItem = (props) => {
    const {eventKey, title, icon, children = null} = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : '';

    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button as={Nav.Link} className="d-flex justify-content-between align-items-center">
            <span>
              <span className="sidebar-icon"><FontAwesomeIcon icon={icon}/> </span>
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">
              {children}
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = (props) => {
    const {title, link, external, target, icon, image, badgeText, badgeBg = 'secondary', badgeColor = 'primary', loadStripeInfos, contactUs} = props;
    const classNames = badgeText ? 'd-flex justify-content-start align-items-center justify-content-between' : '';
    const navItemClassName = link === pathname ? 'active' : '';
    let linkProps = external ? {href: link} : {as: Link, to: link};

    if (!link) {
      linkProps = undefined;
    }

    if (loadStripeInfos === true && navItemClassName === 'active' && stripeInfosLoaded === false) {
      API.getStripeInfos(history).then(response => {
        if (response && response.data.success === true) {
          localStorage.setItem(STRIPE_ACTIVE, response.data.data.active);
          localStorage.setItem(STRIPE_CB, response.data.data.cardPayments);
        } else {
          localStorage.removeItem(STRIPE_ACTIVE);
          localStorage.removeItem(STRIPE_CB);
        }
        setStripeInfosLoaded(true);
      });
    }

    return (
      <Nav.Item className={navItemClassName} onClick={() => {
        setShow(false);
        if (contactUs) {
          setShowContactModal(true);
        }
      }}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon}/> </span> : null}
            {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon"/> : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none">
        <Navbar.Brand className="me-lg-5" as={Link} to={Routes.DashboardOverview.path}>
          <Image src={Logo} className="navbar-brand-light"/>
        </Navbar.Brand>
        <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
          <span className="navbar-toggler-icon"/>
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Image src={Profile} className="card-img-top rounded-circle border-white"/>
                </div>
                <div className="d-block">
                  <h6>{name}</h6>
                  <Button as={Link} variant="secondary" size="xs" to={Routes.Signin.path} className="text-dark"
                          onClick={handleLogout}>
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2"/> <FormattedMessage id="LOGOUT"/>
                  </Button>
                </div>
              </div>
              <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                <FontAwesomeIcon icon={faTimes}/>
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              <NavItem external title={intl.formatMessage({id: 'APP_NAME'})} link={WEBSITE} target="_blank" image={Logo}/>

              <Dropdown.Divider className="my-3 border-indigo"/>

              <NavItem title={intl.formatMessage({id: 'BOOKINGS'})} link={Routes.DashboardOverview.path} icon={faBagShopping}/>

              <CollapsableNavItem eventKey="admin/" title={intl.formatMessage({id: 'MODULE'})} icon={faTable}>
                <NavItem title={intl.formatMessage({id: 'SETTINGS'})} link={Routes.Settings.path} loadStripeInfos={true}/>
                <NavItem title={intl.formatMessage({id: 'VEHICLES'})} link={Routes.Vehicles.path}/>
                <NavItem title={intl.formatMessage({id: 'PACKAGES'})} link={Routes.Packages.path}/>
                <NavItem title={intl.formatMessage({id: 'SURCHARGES'})} link={Routes.Surcharges.path}/>
                <NavItem title={intl.formatMessage({id: 'OPTIONS'})} link={Routes.Options.path}/>
                <NavItem title={intl.formatMessage({id: 'HTML_INTEGRATION'})} link={Routes.HtmlIntegration.path}/>
              </CollapsableNavItem>

              <Dropdown.Divider className="my-3 border-indigo"/>

              <NavItem title={intl.formatMessage({id: 'CONTACT_US'})} icon={faMessage} contactUs/>
              <Button onClick={handlePreviewModule} variant="secondary" className="upgrade-to-pro"><FontAwesomeIcon icon={faEye} className="me-1"/> <FormattedMessage
                id="MODULE_OVERVIEW"/></Button>
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
      <Modal size="lg" as={Modal.Dialog} centered show={showModuleModal} onHide={() => setShowModuleModal(false)}>
        <Modal.Header>
          <Modal.Title className="h6"><FormattedMessage id="MODULE_OVERVIEW"/></Modal.Title>
          <Button variant="close" aria-label="Close" onClick={() => setShowModuleModal(false)}/>
        </Modal.Header>
        <Modal.Body className="td-center">
          <div dangerouslySetInnerHTML={{__html: '<iframe src=\'' + API_URL + '/module/' + moduleIdentifier + '\' width="700" height="450"></iframe>'}}/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" className="text-gray ms-auto" onClick={() => setShowModuleModal(false)}>
            <FormattedMessage id="CLOSE"/>
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal as={Modal.Dialog} centered show={showContactModal} onHide={() => setShowContactModal(false)}>
        <Modal.Header>
          <Modal.Title className="h6"><FormattedMessage id="CONTACT_US"/></Modal.Title>
          <Button variant="close" aria-label="Close" onClick={() => setShowContactModal(false)}/>
        </Modal.Header>
        <Modal.Body>
          <Form id="contact-form" className="text-center" onSubmit={handleContactUs}>
            <FormattedMessage id="FOR_ALL_ENQUIRIES"/>
            <br/><br/>
            <Form.Control required as="textarea" rows="6" id="message"
                          onChange={(e) => setContactMessage(e.target.value)} value={contactMessage}/>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" type="submit" form="contact-form">
            <FormattedMessage id="SEND"/>
          </Button>
          <Button variant="link" className="text-gray ms-auto" onClick={() => setShowContactModal(false)}>
            <FormattedMessage id="CLOSE"/>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
