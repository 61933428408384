import React from 'react';
import {Button, Col, ListGroup, Row} from '@themesberg/react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export default (props) => {
  const {intl, title, statusKey, icon, btnText} = props;
  const status = {
    active: {color: 'success', label: intl.formatMessage({id: 'ACTIVE'})},
    pending: {color: 'warning', label: intl.formatMessage({id: 'PENDING'})},
    inactive: {color: 'danger', label: intl.formatMessage({id: 'INACTIVE'})}
  };

  const statusColor = status[statusKey] ? status[statusKey].color : 'danger'
    , statusLabel = status[statusKey] ? status[statusKey].label : intl.formatMessage({id: 'INACTIVE'});

  return (
    <ListGroup.Item className="px-0">
      <Row className="align-items-center">
        <Col className="ms--2">
          <h4 className="h6 mb-0">
            {title}
          </h4>
          <span className={`text-${statusColor}`}>● </span>
          <small>{statusLabel}</small>
        </Col>
        {btnText && <Col className="col-auto">
          <Button variant="tertiary" size="sm">
            <FontAwesomeIcon icon={icon} className="me-1"/> {btnText}
          </Button>
        </Col>}
      </Row>
    </ListGroup.Item>
  );
};