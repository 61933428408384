import {Alert, Button, Card, Col, Form, InputGroup, Modal, OverlayTrigger, Row, Tooltip} from '@themesberg/react-bootstrap';
import React, {useEffect, useReducer} from 'react';
import Utils from '../services/Utils';
import {useHistory} from 'react-router-dom';
import {API_URL} from '../services/constants';
import {FormattedMessage} from 'react-intl';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowsLeftRight, faArrowsUpDown, faGlobe, faMagnifyingGlass, faMap} from '@fortawesome/free-solid-svg-icons';
import {CompactPicker} from 'react-color';
import API from '../services/API';
import {faGoogle} from '@fortawesome/free-brands-svg-icons';
import Error from '../services/Error';


export default (props) => {

  const focus = ['focus'];
  const hover = ['hover'];
  const [identifier, setIdentifier] = React.useState('');
  const [htmlCode, setHtmlCode] = React.useState('');

  const formReducer = (state, event) => {
    if (event.name || event.value) {
      return {
        ...state,
        [event.name]: typeof event.value === 'boolean' ? event.value : event.value.replace('#', '')
      }
    } else {

      // Set default values
      if (!event.moduleWidth) {
        event.moduleWidth = 600;
      }

      if (!event.moduleHeight) {
        event.moduleHeight = 950;
      }

      if (!event.iconColor) {
        event.iconColor = '80BDFF';
      }

      if (!event.buttonColor) {
        event.buttonColor = '007BFF';
      }

      if (!event.centerCoords) {
        event.centerCoords = '46.41,2.05/5';
      }

      if (!event.redirectionUrl) {
        event.redirectionUrl = '';
      }

      if (event.identifier) {
        setIdentifier(event.identifier);
        setHtmlCode('<iframe src=\'' + API_URL + '/module/' + event.identifier + '\' width="' + event.moduleWidth + '" height="' + event.moduleHeight + '"></iframe>');
      }

      return event;
    }
  }

  const {intl} = props;
  const history = useHistory();
  const [formData, setFormData] = useReducer(formReducer, {});
  const [moduleWidth, setModuleWidth] = React.useState(0); // Only for alert msg
  const [moduleHeight, setModuleHeight] = React.useState(0); // Only for alert msg
  const [showMap, setShowMap] = React.useState(0); // Only for alert msg
  const [apiModal, setApiModal] = React.useState({
    showModal: false,
    placesAPI: true,
    warning: false
  });

  useEffect(() => {
    if (!Utils.isAccessToken(history)) {
      return;
    }

    API.getModule(history).then(response => {
      if (response && response.data.success === true) {
        setFormData(response.data.data);
        setModuleWidth(response.data.data.moduleWidth);
        setModuleHeight(response.data.data.moduleHeight);
        setShowMap(response.data.data.checkboxMap);
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }, [history]);

  const handleChange = event => {
    const isCheckbox = event.target.type === 'checkbox';
    setFormData({
      name: event.target.id,
      value: isCheckbox ? event.target.checked : event.target.value
    })
  }

  const handleChangeColor = (id, color) => {
    setFormData({
      name: id,
      value: color.hex
    })
  }

  const handleChangeCoordinates = (e) => {
    const coordsAndZoom = formData.centerCoords.split('/');
    const coords = coordsAndZoom[0].split(',');
    const zoom = coordsAndZoom[1];

    switch (e.target.id) {
      case 'latitude':
        setFormData({
          name: 'centerCoords',
          value: e.target.value + ',' + coords[1] + '/' + zoom
        })
        break;
      case 'longitude':
        setFormData({
          name: 'centerCoords',
          value: coords[0] + ',' + e.target.value + '/' + zoom
        })
        break;
      case 'zoom':
        setFormData({
          name: 'centerCoords',
          value: coords[0] + ',' + coords[1] + '/' + e.target.value
        })
        break;
    }
  }

  const handleSave = (e) => {
    API.postModule(history, formData).then(response => {
      if (response && response.data.success === true) {
        Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'SAVED_CHANGES'}));
        setModuleWidth(formData.moduleWidth);
        setModuleHeight(formData.moduleHeight);
        setShowMap(formData.checkboxMap);
        // Double setter for force refresh iframe
        setHtmlCode('<iframe src=\'' + API_URL + '/module/' + identifier + '\' width="' + formData.moduleWidth + '" height="' + formData.moduleHeight + '"></iframe> ');
        setHtmlCode('<iframe src=\'' + API_URL + '/module/' + identifier + '\' width="' + formData.moduleWidth + '" height="' + formData.moduleHeight + '"></iframe>');

        if (response.data.data) {
          switch(response.data.data) {
            case Error.googleApiDisabled():
              Utils.notifyWarning(intl.formatMessage({id: 'WARNING'}), intl.formatMessage({id: 'WARNING_GOOGLE_API'}));
              setApiModal({
                showModal: true,
                placesAPI: false,
                warning: false
              });
              break;
            case Error.googleApiWarning():
              setApiModal({
                showModal: true,
                placesAPI: true,
                warning: true
              });
              break;
          }
        }
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  const getArrayCoords = (coords) => {
    if (coords) {
      const coordsAndZoom = coords.split('/');
      const split = coordsAndZoom[0].split(',');
      const arrayCoords = [];
      arrayCoords.push(split[0]);
      arrayCoords.push(split[1]);
      arrayCoords.push(coordsAndZoom[1]);
      return arrayCoords;
    }
    return [46.41, 2.05, 5];
  }

  return (
    <Row>
      <Col xs={12} xl={9}>
        <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0 td-center">
            <div dangerouslySetInnerHTML={{__html: htmlCode}}/>
          </Card.Body>
        </Card>
      </Col>
      <Col xs={12} xl={3}>
        <Row>
          <Col xs={12}>
            {moduleWidth < 250 &&
              <Alert variant="warning">
                <FormattedMessage id="ALERT_MODULE_WIDTH"/>
              </Alert>
            }
            {(!showMap && moduleHeight < 450) &&
              <Alert variant="warning">
                <FormattedMessage id="ALERT_MODULE_HEIGHT"/>
              </Alert>
            }
            <Card border="light" className="shadow-sm mb-4">
              <Card.Body className="pb-0 td-center">
                {formData.vehicle === false &&
                  <Alert variant="danger">
                    <FormattedMessage id="NO_VEHICLES_CONFIGURED"/>
                  </Alert>
                }
                {formData.payment === false &&
                  <Alert variant="danger">
                    <FormattedMessage id="NO_PAYMENT_CONFIGURED"/>
                  </Alert>
                }
                {(formData.vehicle === true && formData.payment === true) &&
                  <div>
                    <h5 className="mb-4"><FormattedMessage id="HTML_CODE_TO_INSERT"/></h5>
                    <Form.Group style={{display: 'inline-block', width: '70%'}}>
                      <Form.Control style={{display: 'inline-block', width: '100%'}} type="textarea" value={htmlCode} disabled/>
                    </Form.Group>
                    <Button style={{display: 'inline-block', marginBottom: '2px'}} variant="light"
                            onClick={() => {
                              navigator.clipboard.writeText(htmlCode);
                              Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'CODE_COPIED'}));
                            }}>
                      <FormattedMessage id="COPY"/>
                    </Button>
                    <br/><br/>
                  </div>
                }
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12}>
            <Card border="light" className="shadow-sm mb-4">
              <Card.Body className="pb-0">
                <Form.Check label={intl.formatMessage({id: 'SHOW_MAP'})} id="checkboxMap"
                            onChange={handleChange}
                            checked={formData.checkboxMap || false}/>
                <OverlayTrigger trigger={hover}
                                overlay={
                                  <Tooltip><FormattedMessage id="TOOLTIP_USER_CAN_CHOOSE_NB_PASSENGERS"/></Tooltip>
                                }>
                  <Form.Check label={intl.formatMessage({id: 'USER_CAN_CHOOSE_NB_PASSENGERS'})} id="checkboxPassengers"
                              onChange={handleChange}
                              checked={formData.checkboxPassengers || false}/>
                </OverlayTrigger>
                <OverlayTrigger trigger={hover}
                                overlay={
                                  <Tooltip><FormattedMessage id="TOOLTIP_USER_CAN_CHOOSE_VEHICLE_TYPE"/></Tooltip>
                                }>
                  <Form.Check label={intl.formatMessage({id: 'USER_CAN_CHOOSE_VEHICLE_TYPE'})} id="checkboxVehicles"
                              onChange={handleChange}
                              checked={formData.checkboxVehicles || false}/>
                </OverlayTrigger>
                <OverlayTrigger trigger={hover}
                                overlay={
                                  <Tooltip><FormattedMessage id="TOOLTIP_USER_CAN_CHOOSE_TRIP_TYPE"/></Tooltip>
                                }>
                  <Form.Check label={intl.formatMessage({id: 'USER_CAN_CHOOSE_TRIP_TYPE'})} id="checkboxTrip"
                              onChange={handleChange}
                              checked={formData.checkboxTrip || false}/>
                </OverlayTrigger>
                <br/>
                <Row>
                  <Form.Label><FormattedMessage id="MODULE_SIZE"/></Form.Label>
                  <Col sm={6} style={{paddingRight: '0.1rem'}}>
                    <Form.Group>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faArrowsLeftRight}/>
                        </InputGroup.Text>
                        <Form.Control required type="number" id="moduleWidth"
                                      onChange={handleChange} value={formData.moduleWidth}/>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col sm={6} style={{paddingLeft: '0.5rem'}}>
                    <Form.Group>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faArrowsUpDown}/>
                        </InputGroup.Text>
                        <Form.Control required type="number" id="moduleHeight"
                                      onChange={handleChange} value={formData.moduleHeight}/>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <br/>
                <Form.Label><FormattedMessage id="GOOGLE_MAPS_API"/></Form.Label>
                <Form.Group>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faGoogle}/>
                    </InputGroup.Text>
                    <OverlayTrigger trigger={focus}
                                    overlay={
                                      <Tooltip><FormattedMessage id="TOOLTIP_GOOGLE_MAPS_API"/></Tooltip>
                                    }>
                      <Form.Control required type="text" id="googleMapsApi"
                                    onChange={handleChange} value={formData.googleMapsApi}/>
                    </OverlayTrigger>
                  </InputGroup>
                </Form.Group>
                <br/>
                {formData.checkboxMap &&
                  <div>
                    <Row>
                      <Form.Label><FormattedMessage id="MAP_CENTER_COORDS"/></Form.Label>
                      <Col sm={6} style={{paddingRight: '0.1rem'}}>
                        <Form.Group>
                          <InputGroup>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faMap}/>
                            </InputGroup.Text>
                            <OverlayTrigger trigger={focus}
                                            overlay={
                                              <Tooltip><FormattedMessage id="LATITUDE"/></Tooltip>
                                            }>
                              <Form.Control required type="number" id="latitude" step="any"
                                            onChange={handleChangeCoordinates} value={getArrayCoords(formData.centerCoords)[0]}/>
                            </OverlayTrigger>
                          </InputGroup>
                        </Form.Group>
                      </Col>
                      <Col sm={6} style={{paddingLeft: '0.5rem'}}>
                        <Form.Group>
                          <InputGroup>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faMap}/>
                            </InputGroup.Text>
                            <OverlayTrigger trigger={focus}
                                            overlay={
                                              <Tooltip><FormattedMessage id="LONGITUDE"/></Tooltip>
                                            }>
                              <Form.Control required type="number" id="longitude" step="any"
                                            onChange={handleChangeCoordinates} value={getArrayCoords(formData.centerCoords)[1]}/>
                            </OverlayTrigger>
                          </InputGroup>
                        </Form.Group>
                      </Col>
                    </Row>
                    <br/>
                    <Form.Group>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faMagnifyingGlass}/>
                        </InputGroup.Text>
                        <OverlayTrigger trigger={focus}
                                        overlay={
                                          <Tooltip><FormattedMessage id="ZOOM_LEVEL"/></Tooltip>
                                        }>
                          <Form.Control required type="number" id="zoom"
                                        onChange={handleChangeCoordinates} value={getArrayCoords(formData.centerCoords)[2]}/>
                        </OverlayTrigger>
                      </InputGroup>
                    </Form.Group>
                    <br/>
                  </div>
                }
                <Row>
                  <Form.Label><FormattedMessage id="REDIRECTION_URL_BOOKING"/></Form.Label>
                  <Col sm={12} style={{paddingRight: '0.1rem'}}>
                    <Form.Group>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faGlobe}/>
                        </InputGroup.Text>
                        <OverlayTrigger trigger={focus}
                                        overlay={
                                          <Tooltip><FormattedMessage id="TOOLTIP_REDIRECTION_URL"/></Tooltip>
                                        }>
                        <Form.Control required type="text" id="redirectionUrl"
                                      onChange={handleChange} value={formData.redirectionUrl}/>
                        </OverlayTrigger>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <br/>
                <Form.Label><FormattedMessage id="ICONS_COLOR"/></Form.Label>
                <br/>
                <CompactPicker id="iconColor" color={formData.iconColor}
                               onChangeComplete={(color) => handleChangeColor('iconColor', color)}/>
                <br/><br/>
                <Form.Label><FormattedMessage id="BUTTON_COLOR"/></Form.Label>
                <br/>
                <CompactPicker id="buttonColor" color={formData.buttonColor}
                               onChangeComplete={(color) => handleChangeColor('buttonColor', color)}/>
                <br/><br/>
                <div className="td-center">
                  <Button variant="primary" onClick={handleSave}>
                    <FormattedMessage id="RECORD"/>
                  </Button>
                </div>
                <br/>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Col>
      <Modal as={Modal.Dialog} centered show={apiModal.showModal} onHide={() => setApiModal({
        showModal: false,
        placesAPI: true,
        warning: false
      })}>
        <Modal.Header>
          <Modal.Title className="h6"><FormattedMessage id="GOOGLE_MAPS_API"/></Modal.Title>
          <Button variant="close" aria-label="Close" onClick={() => setApiModal({
            showModal: false,
            placesAPI: true,
            warning: false
          })}/>
        </Modal.Header>
        <Modal.Body>
          {!apiModal.placesAPI && <FormattedMessage id="GOOGLE_API_DISABLED"/>}
          {apiModal.warning && <FormattedMessage id="GOOGLE_API_CHECK"/>}
          <br/><br/>
          <ul>
            <li><Card.Link href="https://console.cloud.google.com/marketplace/product/google/maps-backend.googleapis.com" target="_blank"><b>Maps JavaScript API</b></Card.Link></li>
            <li><Card.Link href="https://console.cloud.google.com/apis/library/directions-backend.googleapis.com" target="_blank"><b>Directions API</b></Card.Link></li>
            <li><Card.Link href="https://console.cloud.google.com/apis/library/places-backend.googleapis.com" target="_blank"><b>Places API</b></Card.Link></li>
            <li><Card.Link href="https://console.cloud.google.com/apis/library/places.googleapis.com" target="_blank"><b>Places API (New)</b></Card.Link></li>
          </ul>
          {!apiModal.placesAPI && <div><a href="https://console.cloud.google.com/apis/credentials" target="_blank"><FormattedMessage id="CLICK_HERE"/></a><FormattedMessage id="ADD_RESTRICTION_API"/></div>}
          <br/>
          {!apiModal.placesAPI && <div><FormattedMessage id="CHECK_API_KEY"/><br/><i>{formData.googleMapsApi}</i></div>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="link" className="text-gray ms-auto" onClick={() => setApiModal({
            showModal: false,
            placesAPI: true,
            warning: false
          })}>
            <FormattedMessage id="CLOSE"/>
          </Button>
        </Modal.Footer>
      </Modal>
    </Row>
  );
};
