import React, {useEffect, useReducer} from 'react';
import {Button, Card, Col, Form, InputGroup, Modal, OverlayTrigger, Row, Table, Tooltip} from '@themesberg/react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars, faCheck, faCopy, faMap, faPenToSquare, faPlus, faPowerOff, faTrashCan, faXmark} from '@fortawesome/free-solid-svg-icons';
import {FormattedMessage} from 'react-intl';
import {useHistory} from 'react-router-dom';
import Utils from '../services/Utils';
import API from '../services/API';
import Error from '../services/Error';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import IconPicker from '../components/IconPicker';


export default (props) => {

  const formReducer = (state, event) => {
    if (event.value !== undefined) {
      return {
        ...state,
        [event.name]: event.value
      }
    } else {
      return event;
    }
  }

  const {intl} = props;
  const history = useHistory();
  const focus = ['focus'];
  const hover = ['hover'];
  const [showModal, setShowModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [editModalMode, setEditModalMode] = React.useState(false);
  const [currentOptionId, setCurrentOptionId] = React.useState(false);
  const [currentOptionName, setCurrentOptionName] = React.useState('');
  const [vehicles, setVehicles] = React.useState([]);
  const [options, setOptions] = React.useState([]);
  const [formData, setFormData] = useReducer(formReducer, {});
  const [currency, setCurrency] = React.useState({code: 'EUR', symbol: '€'});
  const [icons, setIcons] = React.useState({});
  const [formatter, setFormatter] = React.useState(new Intl.NumberFormat(navigator.language, {style: 'currency', currency: 'EUR'}));

  const refreshOptions = () => {
    API.getOptions(history).then(response => {
      if (response && response.data.success === true) {
        const options = response.data.data.options.sort(function (a, b) {
          return a.position - b.position
        });
        setOptions(options);
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  useEffect(() => {
    if (!Utils.isAccessToken(history)) {
      return;
    }
    refreshOptions();
    API.getCurrency(history).then(response => {
      if (response && response.data.success === true) {
        setFormatter(new Intl.NumberFormat(navigator.language, {
          style: 'currency',
          currency: response.data.data.code,
        }));
        setCurrency(response.data.data);
      }
    });
    API.getIcons(history).then(response => {
      setIcons(response.data.data.icons);
    });
  }, []);

  const getVehicles = () => {
    API.getVehicles(history).then(response => {
      if (response && response.data.success === true) {
        const vehicles = response.data.data.vehicles.sort(function (a, b) {
          return a.position - b.position
        });
        setVehicles(vehicles);
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  const handleChange = event => {
    setFormData({
      name: event.target.id,
      value: event.target.value
    });
  }

  const handleChangeIcon = (iconId) => {
    setFormData({
      name: 'icon',
      value: iconId
    });
  }

  const handleAddOrEdit = (e) => {
    e.preventDefault();

    if (vehicles.length === 0) {
      getVehicles();
    }

    if (editModalMode) {

      if (!formData.vehicleId) {
        formData.vehicleId = -1; // All vehicles
      }

      API.putOptions(history, formData, currentOptionId).then(response => {
        if (response && response.data.success === true) {
          Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'SAVED_CHANGES'}));
          setShowModal(false);
          refreshOptions();
        } else {
          Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
        }
      });
    } else {

      if (!formData.vehicleId) {
        formData.vehicleId = -1; // All vehicles
      }

      API.postOptions(history, formData).then(response => {
        if (response && response.data.success === true) {
          Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'OPTION_ADDED'}));
          setShowModal(false);
          refreshOptions();
        } else {
          Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
        }
      });
    }

  }

  const handleNewOptionButton = () => {
    if (vehicles.length === 0) {
      getVehicles();
    }

    setFormData({});
    setEditModalMode(false);
    setShowModal(true);
  }

  const handleEditOptionButton = (props) => {
    let data = Object.assign({}, props);

    if (vehicles.length === 0) {
      getVehicles();
    }

    setFormData(data);
    setCurrentOptionId(data.id);
    setEditModalMode(true);
    setShowModal(true);
  }

  const handleCopyOptionButton = (props) => {
    API.getCopyOption(history, props.id).then(response => {
      if (response && response.data.success === true) {
        Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'OPTION_COPY'}));
        refreshOptions();
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  const handleEnableOrDisableButton = (props) => {
    if (props.active) {
      API.getDisableOption(history, props.id).then(response => {
        if (response && response.data.success === true) {
          Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'OPTION_DISABLED'}));
          refreshOptions();
        } else {
          Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
        }
      });
    } else {
      API.getEnableOption(history, props.id).then(response => {
        if (response && response.data.success === true) {
          Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'OPTION_ENABLED'}));
          refreshOptions();
        } else if (response && response.data.success === false && response.data.data === Error.optionCannotBeEnabledWithoutVehicule()) {
          Utils.notifyWarning(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'YOU_MUST_ASSIGN_VEHICLE_BEFORE'}));
        } else {
          Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
        }
      });
    }
  }

  const handleDeleteOptionButton = (props) => {
    setCurrentOptionId(props.id);
    setCurrentOptionName(props.name);
    setShowDeleteModal(true);
  }

  const deleteOption = () => {
    API.deleteOptions(history, currentOptionId).then(response => {
      if (response && response.data.success === true) {
        Utils.notifySuccess(intl.formatMessage({id: 'OK'}), intl.formatMessage({id: 'OPTION_DELETED'}));
        setShowDeleteModal(false);
        refreshOptions();
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    // update position variable
    let index = 0;
    for (const [key, value] of Object.entries(result)) {
      value.position = index++;
    }

    return result;
  };

  const handleDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      options,
      result.source.index,
      result.destination.index
    );

    setOptions(items);

    // Prepare data for backend
    let data = {
      options: []
    };

    for (const [key, value] of Object.entries(items)) {
      data.options.push({
        id: value.id,
        position: value.position
      });
    }

    API.postReorderOptions(history, data).then(response => {
      if (response && response.data.success === true) {
        // Everything is OK
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    });
  }

  const TableRow = (props) => {
    const {key, provided, icon, name, vehicleId, vehicleName, maxValue, pricePerQuantity, active} = props;

    return (
      <tr key={key} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
        <td className="td-center">
          <FontAwesomeIcon icon={faBars}/>
        </td>
        <td className="td-center">
          <div dangerouslySetInnerHTML={{__html: icons[icon]}}/>
        </td>
        <td className="td-center">
          {name}
        </td>
        <td className="td-center">
          {vehicleId === -1 ? intl.formatMessage({id: 'ALL_VEHICLES'}) : vehicleName}
        </td>
        <td className="td-center">
          {maxValue}
        </td>
        <td className="td-center">
          {formatter.format(pricePerQuantity)}
        </td>
        <td className="td-center">
          {active ? <FontAwesomeIcon icon={faCheck}/> : <FontAwesomeIcon icon={faXmark}/>}
        </td>
        <td className="td-center">
          <OverlayTrigger trigger={hover}
                          overlay={
                            <Tooltip><FormattedMessage id="EDIT"/></Tooltip>
                          }>
            <Button style={{marginRight: '5px'}} variant="light" onClick={() => handleEditOptionButton(props)}>
              <FontAwesomeIcon icon={faPenToSquare}/>
            </Button>
          </OverlayTrigger>
          <OverlayTrigger trigger={hover}
                          overlay={
                            <Tooltip><FormattedMessage id="COPY"/></Tooltip>
                          }>
            <Button style={{marginRight: '5px'}} variant="gray" onClick={() => handleCopyOptionButton(props)}>
              <FontAwesomeIcon icon={faCopy}/>
            </Button>
          </OverlayTrigger>
          <OverlayTrigger trigger={hover}
                          overlay={
                            <Tooltip>{active ? <FormattedMessage id="DISABLE"/> : <FormattedMessage id="ENABLE"/>}</Tooltip>
                          }>
            <Button style={{marginRight: '5px'}} variant="warning" onClick={() => handleEnableOrDisableButton(props)}>
              <FontAwesomeIcon icon={faPowerOff}/>
            </Button>
          </OverlayTrigger>
          <OverlayTrigger trigger={hover}
                          overlay={
                            <Tooltip><FormattedMessage id="DELETE"/></Tooltip>
                          }>
            <Button variant="danger" onClick={() => handleDeleteOptionButton(props)}>
              <FontAwesomeIcon icon={faTrashCan}/>
            </Button>
          </OverlayTrigger>
        </td>
      </tr>
    );
  };

  return (
    <div>
      <br/>
      <Button variant="secondary" className="text-dark me-2" onClick={handleNewOptionButton}>
        <FontAwesomeIcon icon={faPlus} className="me-2"/>
        <span><FormattedMessage id="NEW_OPTION"/></span></Button>
      <br/><br/>
      {(options && options.length > 0) &&
        <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0">
            <Row>
              <Col lg={12}>
                <Table responsive className="table-centered table-nowrap rounded mb-0">
                  <thead className="thead-light">
                  <tr>
                    <th className="border-0 text-center"></th>
                    <th className="border-0 text-center"><FormattedMessage id="ICON"/></th>
                    <th className="border-0 text-center"><FormattedMessage id="NAME"/></th>
                    <th className="border-0 text-center"><FormattedMessage id="VEHICLE"/></th>
                    <th className="border-0 text-center"><FormattedMessage id="MAX_QUANTITY"/></th>
                    <th className="border-0 text-center"><FormattedMessage id="PRICE_PER_QUANTITY"/></th>
                    <th className="border-0 text-center"><FormattedMessage id="ACTIVE_TAB"/></th>
                    <th className="border-0 text-center"><FormattedMessage id="ACTIONS"/></th>
                  </tr>
                  </thead>
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="table-body">
                      {(provided, snapshot) => (
                        <tbody ref={provided.innerRef} {...provided.droppableProps}>
                        {options && options.map((data, index) => {
                            return (
                              <Draggable
                                draggableId={data.id.toString()}
                                key={data.id.toString()}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <TableRow key={`option-${data.id}`} provided={provided} {...data} />
                                )}
                              </Draggable>
                            )
                          }
                        )}
                        {provided.placeholder}
                        </tbody>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Table>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      }
      <Modal as={Modal.Dialog} centered show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title className="h6">{editModalMode ? <FormattedMessage id="EDIT_OPTION"/> : <FormattedMessage id="NEW_OPTION"/>}</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={() => setShowModal(false)}/>
        </Modal.Header>
        <Modal.Body>
          <Form id="add-option-form" onSubmit={handleAddOrEdit}>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Group>
                  <Form.Label><FormattedMessage id="NAME"/></Form.Label>
                  <Form.Control required type="text" id="name" placeholder={intl.formatMessage({id: 'OPTION_NAME_HINT'})}
                                onChange={handleChange} value={formData.name}/>
                </Form.Group>
              </Col>
            </Row>
            <br/>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label><FormattedMessage id="ICON"/></Form.Label>
                  <br/>
                  <IconPicker icons={icons} currentIcon={formData.icon} onChange={handleChangeIcon}/>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group className="mb-3">
                  <Form.Label><FormattedMessage id="VEHICLE"/></Form.Label>
                  <Form.Select id="vehicleId" onChange={handleChange} value={formData.vehicleId}>
                    <option key={`vehicle-all`} value={-1}>{intl.formatMessage({id: 'ALL_VEHICLES'})}</option>
                    {vehicles.map(v => <option key={`vehicle-${v.id}`} value={v.id}>{v.name}</option>)}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <br/>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label><FormattedMessage id="MAX_QUANTITY" values={{newLine: ' '}}/></Form.Label>
                  <OverlayTrigger trigger={focus}
                                  overlay={
                                    <Tooltip><FormattedMessage id="TOOLTIP_MAX_QUANTITY"/></Tooltip>
                                  }>
                    <Form.Control type="number" id="maxValue"
                                  onChange={handleChange} value={formData.maxValue}/>
                  </OverlayTrigger>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label><FormattedMessage id="PRICE_PER_QUANTITY" values={{newLine: ' '}}/></Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={Utils.getCurrencyIcon(currency.code)}/>
                    </InputGroup.Text>
                    <Form.Control required type="number" id="pricePerQuantity" step="any"
                                  onChange={handleChange} value={formData.pricePerQuantity}/>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" type="submit" form="add-option-form">
            {editModalMode ? <FormattedMessage id="RECORD"/> : <FormattedMessage id="ADD"/>}
          </Button>
          <Button variant="link" className="text-gray ms-auto" onClick={() => setShowModal(false)}>
            <FormattedMessage id="CLOSE"/>
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal as={Modal.Dialog} centered show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header>
          <Modal.Title className="h6"><FormattedMessage id="DELETE_OPTION" values={{name: currentOptionName}}/></Modal.Title>
          <Button variant="close" aria-label="Close" onClick={() => setShowDeleteModal(false)}/>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="danger" onClick={deleteOption}>
            <FormattedMessage id="DELETE"/>
          </Button>
          <Button variant="link" className="text-gray ms-auto" onClick={() => setShowDeleteModal(false)}>
            <FormattedMessage id="CLOSE"/>
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
