import React, {useEffect} from 'react';
import CbSuccess from '../../assets/img/cb_success.png';
import {Button, Image} from '@themesberg/react-bootstrap';
import {FormattedMessage} from 'react-intl';
import queryString from 'query-string';
import API from '../../services/API';

export default (props) => {

  const params = queryString.parse(props.location.search);
  const id = params.id;
  let url;

  useEffect(() => {
    if (id) {
      API.getBookingUrl(id).then(response => {
        if (response) {
          url = response.data;
        }
      });
    }

    setTimeout(() => {
      if (url) {
        window.location.replace(url);
      }
    }, 6000);
  });

  const handleButton = event => {
    if (url) {
      window.location.replace(url);
    }
  }

  return (
    <div>
      {id &&
        <div className="text-center m-5">
          <Image src={CbSuccess} className="mb-5" style={{height: 'auto', width: '200px'}}/>
          <h1 style={{color: '#43D19E'}}><FormattedMessage id="THANK_YOU"/></h1>
          <b><FormattedMessage id="PAYMENT_DONE_SUCCESSFULLY"/></b>
          <br/><br/>
          <FormattedMessage id="YOU_WILL_BE_REDIRECTED"/>
          <br/><br/>
          <Button variant="primary" className="btn-green" onClick={handleButton}>
            <FormattedMessage id="BACK"/>
          </Button>
        </div>}
    </div>
  );
};