import React, {useEffect} from 'react';
import API from '../../services/API';

export default () => {

  useEffect(() => {
    API.getLifetimeSubscriptionUrl().then(response => {
      if (response) {
        window.location.replace(response.data);
      }
    });
  }, []);

  return (
    <></>
  );
};