import React, {useEffect} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope, faUnlockAlt} from '@fortawesome/free-solid-svg-icons';
import {Button, Card, Col, Container, Form, Image, InputGroup, Row} from '@themesberg/react-bootstrap';
import {Link, useHistory} from 'react-router-dom';

import {Routes} from '../../routes';
import API from '../../services/API';
import Utils from '../../services/Utils';
import {ACCESS_TOKEN, EMAIL, NAME, PHONE} from '../../services/constants';
import {FormattedMessage} from 'react-intl';
import Logo from '../../assets/img/logo.png';


export default (props) => {
  Utils.setBackground(true);

  const {intl} = props;
  const history = useHistory();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [rememberMe, setRememberMe] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    Utils.checkAccessTokenForLogin(history);
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    API.postSignIn(email, password, rememberMe).then((response => {
      setLoading(false);
      if (response && response.data.success === true) {
        localStorage.setItem(ACCESS_TOKEN, response.data.data.accessToken);
        localStorage.setItem(EMAIL, response.data.data.email);
        localStorage.setItem(NAME, response.data.data.name);
        if (response.data.data.phone) {
          localStorage.setItem(PHONE, response.data.data.phone);
        } else {
          localStorage.setItem(PHONE, '');
        }
        history.push('/admin/dashboard');
      } else {
        Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
      }
    }))
      .catch(error => {
        setLoading(false);
        if (error.response.status === 401) {
          Utils.notifyWarning(intl.formatMessage({id: 'UNABLE_TO_CONNECT'}), intl.formatMessage({id: 'INCORRECT_LOGIN'}));
        } else {
          Utils.notifyError(intl.formatMessage({id: 'ERROR'}), intl.formatMessage({id: 'ERROR_OCCURRED'}));
        }
      });
  }

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-5 mb-lg-5">
        <Container>
          <a href="https://clickchauffeur.com"><Image src={Logo} alt="Logo" className="logo"/></a>
          <Row className="justify-content-center form-bg-image">
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0"><FormattedMessage id="LOGIN"/></h3>
                </div>
                <Form className="mt-4" onSubmit={handleSubmit}>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label><FormattedMessage id="EMAIL_ADDRESS"/></Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope}/>
                      </InputGroup.Text>
                      <Form.Control autoFocus required type="email" placeholder="example@company.com"
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}/>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label><FormattedMessage id="PASSWORD"/></Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt}/>
                        </InputGroup.Text>
                        <Form.Control required type="password" placeholder={intl.formatMessage({id: 'PASSWORD'})}
                                      onChange={(e) => setPassword(e.target.value)}
                                      value={password}/>
                      </InputGroup>
                    </Form.Group>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <Form.Check label={intl.formatMessage({id: 'REMEMBER_ME'})} id="rememberMe"
                                  onChange={(e) => setRememberMe(e.target.checked)}
                                  checked={rememberMe}/>
                      <Card.Link as={Link} to={Routes.ForgotPassword.path} className="small text-end"><FormattedMessage id="FORGOT_PASSWORD"/></Card.Link>
                    </div>
                  </Form.Group>
                  <Button variant="primary" type="submit" className="w-100 btn-login" disabled={loading}>
                    {loading && (
                      <i className="spinner-border spinner-border-sm text-light"/>
                    )}
                    {!loading && <FormattedMessage id="SIGN_IN"/>}
                  </Button>
                </Form>
                <br/>
                <div className="text-center text-md-center">
                  <a href="https://member.clickchauffeur.com/inscription-fr-etape-1"><FormattedMessage id="REGISTRATION"/></a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
